import React from "react";
import "./MainAnkerpay.css";
import { Collapse } from "react-collapse";

class MainAnkerpay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showBuy: false, showVolatility: false, showAccept: false };
    this.toggleBuy = this.toggleBuy.bind(this);
    this.toggleVolatility = this.toggleVolatility.bind(this);
    this.toggleAccept = this.toggleAccept.bind(this);
  }

  toggleBuy() {
    this.setState({ showBuy: !this.state.showBuy });
  }
  toggleVolatility() {
    this.setState({ showVolatility: !this.state.showVolatility });
  }
  toggleAccept() {
    this.setState({ showAccept: !this.state.showAccept });
  }

  render() {
    return (
      <div className="main-ankerpay">
        <div className="container">
          <div className="column">
            <div className="text">
              <h3>
                <span>Anker.</span>POS.
              </h3>
              <p>
                AnkerPOS APIs allow for simple integration into current and existing payment providers. Add invoices to your website or application to allow users to pay with bitcoin or other crypto currencies.
              </p>
              <div className="collapse">
                <div
                  className={
                    this.state.showVolatility
                      ? "collapse-title active"
                      : "collapse-title"
                  }
                  onClick={this.toggleVolatility}
                >
                  <span>NO Volatility</span>
                  <i className="fas fa-chevron-right"></i>
                </div>
                <Collapse isOpened={this.state.showVolatility}>
                  <div className="collapse-text">
                    <p>
                      The point of sale systems will be an integral part of
                      adoption. These can be deployed alongside the ATM in
                      shopping malls and stores in order for users to use their
                      newly bought BTC or ANK.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="collapse">
                <div
                  className={
                    this.state.showAccept
                      ? "collapse-title active"
                      : "collapse-title"
                  }
                  onClick={this.toggleAccept}
                >
                  <span>Accept Crypto Currencies</span>
                  <i className="fas fa-chevron-right"></i>
                </div>
                <Collapse isOpened={this.state.showAccept}>
                  <div className="collapse-text">
                    <p>
                      The AnkerPOS application is an all in one crypto
                      acceptance application that allow institutions, shop
                      owners merchants and vendors to easily and readily accept
                      crypto payments. The buy function can be used to turn any
                      shop or store into a crypto over the counter trading desk.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="collapse">
                <div
                  className={
                    this.state.showBuy
                      ? "collapse-title active"
                      : "collapse-title"
                  }
                  onClick={this.toggleBuy}
                >
                  <span>Buy & Sell</span>
                  <i className="fas fa-chevron-right"></i>
                </div>
                <Collapse isOpened={this.state.showBuy}>
                  <div className="collapse-text">
                    <p>
                      The sell function can provide large stores and casinos
                      with an easy crypto payment solution that settles the
                      transaction in their local currency. The pay function is
                      aimed at merchants that want to use crypto acceptance for
                      their products in-store this is all linked to a dashboard
                      with a complete list of transactions and Invoices.
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className="img">
              <img src="img/third-column-img.png" />
              <a href="https://pos.ankerid.com/" target="_blanc">
                AnkerPay
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainAnkerpay;
