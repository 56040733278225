import Header from "./components/Home/Header/Header";
import Ecosystem from "./components/Home/Ecosystem/Ecosystem";
import Platform from "./components/Home/Platform/Platform";
import Grnetw from "./components/Ankerpay/Grnetw/Grnetw";
import Banking from "./components/Home/Banking/Banking";
import Timeline from "./components/Home/Timeline/Timeline";
import Exchange from "./components/Ankerpay/Exchange/Exchange";
import Partners from "./components/Home/Partners/Partners";
import Video from "./components/Home/Video/Video";
import MainAnkerpay from "./components/Ankerpay/MainAnkerpay/MainAnkerpay";
import Schema from "./components/Ankerpay/Schema/Schema";
import AnkerPlugin from "./components/Ankerpay/AnkerPlugin/AnkerPlugin";
import MobileWallet from "./components/Ankerpay/MobileWallet/MobileWallet";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <Header />
      <Ecosystem />
      <MainAnkerpay />
      <AnkerPlugin />
      <Schema />
      <MobileWallet />

      <Banking />
      <Grnetw />

      <Partners title={"Partners"} />

    </React.Fragment>
  );
}

export default App;
