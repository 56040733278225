import React from "react";
import "./Grnetw.css";
import Slider from "react-slick";

class Grnetw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="growing">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <div className="container">
          <div className="main-growing">
            <h2>
              AnkerPay's growing <span>Network.</span>
            </h2>

          </div>
          
          
          
          
          <div className="main-grn">
            <div className="left-grn">
              <div className="box">

                <h2>
                  THE <span>ANKERPAY</span> MOYA APP INTEGRATION.
                </h2>
                <p>AnkerPay recently integrated with Moya App, the fastest growing App in South Africa with over 6 million weekly active users. Users can buy or sell bitcoin in the app and use it to pay at merchants with a mastercard debit card. </p>
              </div>  
            </div>  
          
            <div className="right-grn">
              <img src="/img/grcard.png" />
              
            </div>  
          </div>  
          
          
          <div className="main-grn">
            <div className="left-grn">
              <img src="/img/grmap.png" />
              
            </div>  
            <div className="right-grn">
              <div className="box">
                <h2>
                  THE <span>ANKERPAY</span> ATM APP.
                </h2>
                <p>The AnkerPay ATM APP allows South Africans to buy bitcoin from hundreds of locations country-wide. With withdrawals coming soon.</p>
              </div>  
            </div>  
          
          </div>  

          
        </div>
      </div>
    );
  }
}

export default Grnetw;
