import React from "react";
import "./Form.css";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      website: "",
      message: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    console.log("form is submitted");
  }

  render() {
    return (
      <div className="form">
        <div className="container">
          <div className="main-form">
            <div className="left-form">
              <h6>For more information, send us an email.</h6>
              <h2>
                Please get in <span>touch</span> with <span>us</span>
              </h2>
            </div>
            <div className="right-form">
              <form onSubmit={this.handleSubmit}>
                <input
                  name="name"
                  placeholder="Name"
                  value={this.state.name}
                ></input>
                <input
                  name="email"
                  placeholder="E-mail"
                  value={this.state.name}
                ></input>
                <input
                  name="website"
                  placeholder="Website"
                  value={this.state.website}
                ></input>
                <textarea
                  value={this.state.message}
                  name="message"
                  rows="6"
                  placeholder="Message"
                ></textarea>

                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="bottom-features">
            <div>
              Want to run a AnkerNode? <span>Let's help you get started.</span>
            </div>
            <a
              href="https://github.com/AnkerPay/Anker/blob/master/doc/masternode_conf_gui.md"
              target="_blanc"
            >
              start now
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
