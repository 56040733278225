import React from "react";
import "./Locations.css";
import Slider from "react-slick";

class Locations extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            centerMode: true,
          },
        },
        {
          breakpoint: 10000,
          settings: "unslick",
        },
      ],
    };
    return (
      <div className="locations">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <div className="container">
          <div className="main-locations">
            <h2>
              <span>ATM </span> Locations:
            </h2>
            <div className="subtitle">
              <span>As seen on:</span>
              <div class="subtitle-icon">
                <span>
                  Coin ATM
                  <br /> radar
                </span>
                <img src="img/locations-icon.png" />
              </div>
            </div>

            <Slider {...settings}>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinatmradar.com/bitcoin_atm/14321/bitcoin-atm-ankerpay-cape-town-willow-bridge/"
                    target="_blanc"
                  >
                    <img src="img/locations-1.png" />
                    <span>Bitcoin ATM WillowBridge</span>
                  </a>
                </div>
              </div>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinatmradar.com/bitcoin_atm/11898/bitcoin-atm-ankerpay-cape-town-ankerpay/"
                    target="_blanc"
                  >
                    <img src="img/locations-2.png" />
                    <span>Bitcoin ATM Cape Town</span>
                  </a>
                </div>
              </div>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinmap.org/view/#/venue/18587"
                    target="_blanc"
                  >
                    <img src="img/locations-3.png" />
                    <span>Bitcoin ATM & POS Tranquility Spa</span>
                  </a>
                </div>
              </div>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinatmradar.com/bitcoin_atm/9982/bitcoin-atm-ankerpay-stellenbosch-eikestad-mall/"
                    target="_blanc"
                  >
                    <img src="img/locations-4.png" />
                    <span>Bitcoin ATM Stellenbosch</span>
                  </a>
                </div>
              </div>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinatmradar.com/bitcoin_atm/23567/bitcoin-atm-ankerpay-johannesburg-lonehill-shopping-centre/"
                    target="_blanc"
                  >
                    <img src="img/locations-5.png" />
                    <span>Bitcoin ATM Johannesburg</span>
                  </a>
                </div>
              </div>
              <div className="network-slide">
                <div className="network-block">
                  <a
                    href="https://coinatmradar.com/bitcoin_atm/23291/bitcoin-atm-ankerpay-port-elizabeth-summerstrand-village-centre/"
                    target="_blanc"
                  >
                    <img src="img/locations-6.png" />
                    <span>Bitcoin ATM Port Elizabeth</span>
                  </a>
                </div>
              </div>
            </Slider>
            <iframe
              style={{ marginTop: "100px" }}
              src="https://www.google.com/maps/d/embed?mid=1-wy7RQF7oMV64WMKscNuDxgPGnexuIZz"
              width="100%"
              height="480"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Locations;
