import React from "react";
import "./MainAnkerplatform.css";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

class MainAnkerplatform extends React.Component {
  render() {
    return (
      <div className="ankerplatform">
        <div className="container">
          <div className="ankerplatform-main">
            <div className="ankerplatform-main-title">
              <h2>
                <span> Ankerpay </span> platform
              </h2>
              <Link
                activeClass="active"
                to="explained"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <button>Explore</button>
              </Link>
            </div>
            <div className="explained" id="explained">
              <div className="left-explained">
                <h3>
                  <span>Anker </span>Platform.<span> Explained.</span>
                </h3>
                <p>
                  AnkerPlatform is a pure decentralized multi-chain
                  interoperable platform for the creation of decentralized
                  finance applications that connects them to an environment of
                  services that is vital for future growth and global adoption.
                </p>
                <Link
                  activeClass="active"
                  to="solutions"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <button>Learn more</button>
                </Link>
              </div>
              <div className="right-explained">
                <div className="explained-block">
                  <svg
                    width="67"
                    height="67"
                    viewBox="0 0 67 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M16.75 4.1875V0H2.09375C0.937346 0 0 0.937346 0 2.09375V16.75H4.1875V7.13969L23.6384 26.5906L26.5906 23.6384L7.13969 4.1875H16.75Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M64.9065 0H50.2503V4.1875H59.8606L40.4097 23.6384L43.3619 26.5906L62.8128 7.13969V16.75H67.0003V2.09375C67.0003 0.937346 66.063 0 64.9065 0Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M62.8128 59.8601L43.3619 40.4092L40.4097 43.3614L59.8606 62.8123H50.2503V66.9998H64.9065C66.063 66.9998 67.0003 66.0625 67.0003 64.9061V50.2498H62.8128V59.8601Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M23.6384 40.3887L4.1875 59.8605V50.2502H0V64.9065C0 66.0629 0.937346 67.0002 2.09375 67.0002H16.75V62.8127H7.13969L26.5906 43.3618L23.6384 40.3887Z"
                        fill="#01AAEC"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="67" height="67" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>AnkerPlatform </h6>
                  <p>
                    As part of the AnkerPay ecosystem, a key component is the
                    AnkerPlatform that enables the creation of exchanges that
                    provides liquidity for an AnkerDex and various other
                    decentralized finance applications. AnkerPlatform is
                    interoperable and decentralized at its core making it safe
                    and secure for the next generation of financial applications
                    to be built.
                  </p>
                </div>
                <div className="explained-block">
                  <svg
                    width="67"
                    height="67"
                    viewBox="0 0 67 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M64.6213 9.24247C63.1817 9.11121 61.9085 10.1718 61.7773 11.6113L61.4305 15.4152C55.5472 6.17578 45.3344 0 33.5009 0C20.7708 0 9.55209 7.26557 3.98298 18.1703C3.32554 19.4576 3.83616 21.0341 5.12342 21.6915C6.41095 22.3489 7.98715 21.8385 8.64458 20.5511C13.3577 11.3223 22.8224 5.23438 33.5009 5.23438C43.2339 5.23438 52.0174 10.3027 57.0495 18.2428L53.3177 15.5776C52.1415 14.7376 50.5069 15.0101 49.6669 16.1863C48.8269 17.3626 49.0994 18.997 50.2755 19.8371L61.5916 27.9191C63.2501 29.1014 65.5377 28.0191 65.719 26.027L66.9898 12.0866C67.1212 10.647 66.0607 9.37372 64.6213 9.24247Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M61.8769 45.3087C60.5895 44.6514 59.0131 45.1619 58.3557 46.4491C53.6426 55.6778 44.1779 61.7658 33.4994 61.7658C23.7664 61.7658 14.9829 56.6975 9.95083 48.7573L13.6825 51.4226C14.8588 52.2625 16.4933 51.9901 17.3334 50.8139C18.1734 49.6376 17.9009 48.0032 16.7248 47.1631L5.4087 39.0811C3.77047 37.9107 1.46447 38.9606 1.28127 40.9732L0.0104918 54.9136C-0.12076 56.3531 0.939855 57.6263 2.37918 57.7576C3.82046 57.8886 5.09202 56.8269 5.22314 55.3888L5.56992 51.5848C11.4531 60.8244 21.6659 67.0002 33.4994 67.0002C46.2295 67.0002 57.4482 59.7346 63.0173 48.8298C63.6748 47.5426 63.1641 45.9661 61.8769 45.3087Z"
                        fill="#01AAEC"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="67" height="67" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h6>AnkerSwaps</h6>
                  <p>
                    One such use case is making native cross platform swaps that
                    doesn't depend of on the use of the same cryptographic hash
                    function, such as Atomic swaps. AnkerSwaps is a major leap
                    towards interchangeability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainAnkerplatform;
