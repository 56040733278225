import React from "react";
import "./Map.css";
class Map extends React.Component {
  render() {
    return (
      <div className="map">
        <div className="container">
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1gl2oSlpTXC-NXaWi9gHA4wI73Mq2qw5U"
            width="100%"
            height="480"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Map;
