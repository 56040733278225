import React from "react";
import "./CommunityMain.css";

class CommunityMain extends React.Component {
  render() {
    return (
      <div className="community-main">
        <div className="container">
          <div className="main-community-center">
            <div className="community-column">
              <div className="community-left">
                <h3>
                  Co<span>mm</span>unity
                </h3>
              </div>
              <div className="community-right">
                <div className="community-block">
                  <div className="community-text">
                    <h5>Community-based Coin</h5>
                    <p>
                      AnkerPay is launched free of ICO, IEO's or large premines.
                      Built by the community for the community. Join our
                      Twitter, Telegram, Reddit or send us a mail to contribute
                      to AnkerPay.
                    </p>
                  </div>
                  <svg
                    width="207"
                    height="207"
                    viewBox="0 0 207 207"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M189 173.396C200.643 160.904 206.99 145.462 206.99 129.372C206.99 109.24 197.045 91.0085 181.117 78.0723C180.753 124.198 137.377 161.713 84.0958 161.713H48.0361C61.983 184.715 90.3213 200.521 122.904 200.521H200.521C203.149 200.521 205.494 198.945 206.504 196.519C207.515 194.094 206.949 191.304 205.09 189.485L189 173.396Z"
                        fill="#64B5F6"
                      />
                      <path
                        d="M84.0957 6.47852C37.7276 6.47852 0.0105216 38.3743 0.0105216 77.6275C0.0105216 93.7169 6.35734 109.119 17.9999 121.651L1.91052 137.74C0.0509472 139.6 -0.515011 142.389 0.495629 144.774C1.50627 147.2 3.85095 148.777 6.47861 148.777H84.0957C130.464 148.777 168.181 116.881 168.181 77.6275C168.181 38.3743 130.464 6.47852 84.0957 6.47852Z"
                        fill="#2196F3"
                      />
                      <path
                        d="M122.905 58.2236H45.2874C41.73 58.2236 38.8193 61.1343 38.8193 64.6917C38.8193 68.2492 41.73 71.1598 45.2874 71.1598H122.905C126.462 71.1598 129.373 68.2492 129.373 64.6917C129.373 61.1343 126.462 58.2236 122.905 58.2236Z"
                        fill="#FAFAFA"
                      />
                      <path
                        d="M97.0322 84.0962H45.2874C41.73 84.0962 38.8193 87.0068 38.8193 90.5643C38.8193 94.1217 41.73 97.0324 45.2874 97.0324H97.0322C100.59 97.0324 103.5 94.1217 103.5 90.5643C103.5 87.0068 100.59 84.0962 97.0322 84.0962Z"
                        fill="#FAFAFA"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="207" height="207" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="community-column">
              <div className="community-left">
                <h3>
                  <span>Community.</span>
                  <br /> Voting.
                </h3>
                <p>
                  AnkerPay is a community-based coin. Anyone can make a proposal
                  for the future of AnkerPay and build the community. Node
                  operators govern the chain by giving guidance about what
                  happens next. Voting takes place every three months.
                  <span>*Testnet</span>
                </p>
              </div>
              <div className="community-right">
                <img src="/img/community.png" />
                <button>Dashbord</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommunityMain;
