import React, { Component } from "react";
import "./MobileWallet.css";
import Slider from "react-slick";
import Header from "./components/Header/Header";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      activeSlide2: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  firstMobile() {
    let classname;
    if (this.state.activeSlide == 0) {
      classname = "active-img";
    }
    if (this.state.activeSlide == 1) {
      classname = "first-mobile";
    }
    if (this.state.activeSlide == 2) {
      classname = "second-mobile";
    }
    return classname;
  }

  secondMobile() {
    let classname;
    if (this.state.activeSlide == 2) {
      classname = "active-img";
    }
    if (this.state.activeSlide == 0) {
      classname = "first-mobile";
    }
    if (this.state.activeSlide == 1) {
      classname = "second-mobile";
    }
    return classname;
  }

  thirdMobile() {
    let classname;
    if (this.state.activeSlide == 1) {
      classname = "active-img";
    }
    if (this.state.activeSlide == 2) {
      classname = "first-mobile";
    }
    if (this.state.activeSlide == 0) {
      classname = "second-mobile";
    }
    return classname;
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      verticalSwiping: true,
      dots: false,
      arrows: false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
    };

    return (
      <React.Fragment>
        <Header wallet={true} title="Mobile wallet" />
        <div className="mobile-wallet" onWheel={this.next}>
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <div className="container">
            <div className="main-mobile-wallet">
              <div className="mobile-wallet-left">
                <div className={this.firstMobile()}>
                  <img src="/img/third-mobile.png" />
                </div>
                <div className={this.secondMobile()}>
                  <img src="/img/first-mobile.png" />
                </div>
                <div className={this.thirdMobile()}>
                  <img src="/img/second-mobile.png" />
                </div>
              </div>
              <div className="mobile-wallet-right">
                <Slider ref={(c) => (this.slider = c)} {...settings}>
                  <div className="wallet-slide">
                    <h2>All in one Crypto Solution.</h2>
                    <span>
                      We take things to a totally new level with the AnkerPay
                      Wallet. Buy, sell and exchange in your pocket.
                    </span>
                  </div>
                  <div className="wallet-slide">
                    <h2>Keep Track With Portfolio.</h2>
                    <span>
                      We have partnered with the best to make buying and selling
                      simple. So anyone can use it.
                    </span>
                  </div>
                  <div className="wallet-slide">
                    <h2>Exchange on the go.</h2>
                    <span>
                      Exchange referral bonus. Refer a friend get 35% of the
                      fees.
                    </span>
                  </div>
                </Slider>
                <div className="wallet-downloads">
                  <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                    <img src="/img/app-store.png" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet">
                    <img src="/img/googleplay.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="wallet-mobile-links">
              <a href="https://twitter.com/AnkerPay" target="_blanc">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M35.0001 6.66284C33.6231 7.2551 32.2459 7.62497 30.8695 7.77354C32.4241 6.84081 33.4756 5.52313 34.0234 3.82056C32.6014 4.6643 31.0841 5.24155 29.4705 5.55261C28.049 4.04248 26.3023 3.28711 24.2291 3.28711C22.2454 3.28711 20.554 3.98656 19.1543 5.38576C17.7555 6.78488 17.0559 8.47625 17.0559 10.4601C17.0559 10.993 17.115 11.541 17.2336 12.1035C14.302 11.9555 11.552 11.219 8.98305 9.89389C6.41426 8.56866 4.23417 6.80309 2.44277 4.59717C1.79132 5.70732 1.46548 6.91416 1.46548 8.21699C1.46548 9.44584 1.75414 10.586 2.33185 11.6372C2.90917 12.6882 3.68664 13.5395 4.66386 14.191C3.50882 14.1465 2.42807 13.8429 1.42145 13.2803V13.3691C1.42145 15.1015 1.96556 16.6224 3.05362 17.9331C4.14184 19.2436 5.51514 20.0685 7.17321 20.4092C6.55132 20.5719 5.92196 20.6533 5.28544 20.6533C4.87084 20.6533 4.41922 20.6164 3.93073 20.5429C4.38966 21.9787 5.23364 23.159 6.46233 24.0848C7.69127 25.0102 9.083 25.4871 10.6376 25.5175C8.03197 27.5603 5.06352 28.5817 1.73228 28.5817C1.09553 28.5817 0.518284 28.5527 0 28.4931C3.33132 30.6396 7.00302 31.7129 11.0153 31.7129C13.5619 31.7129 15.953 31.3099 18.1879 30.5028C20.424 29.6963 22.3337 28.6152 23.918 27.2603C25.5019 25.9056 26.868 24.3473 28.0153 22.5856C29.1626 20.8237 30.0173 18.9843 30.5804 17.067C31.1428 15.1494 31.4242 13.2288 31.4242 11.3037C31.4242 10.8892 31.4167 10.5784 31.4019 10.371C32.8088 9.34993 34.008 8.11346 35.0001 6.66284Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="35" height="35" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="https://t.me/AnkerPay" target="_blanc">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M34.4588 3.47698C34.031 2.95414 33.3906 2.66602 32.6555 2.66602C32.2555 2.66602 31.8323 2.7504 31.397 2.91649L1.77001 14.2259C0.197747 14.8259 -0.014006 15.7263 -0.000387575 16.2099C0.0132308 16.6933 0.275719 17.5803 1.87949 18.0903C1.8891 18.0935 1.89871 18.0962 1.90833 18.0992L6.06328 19.288L26.2986 8.94998C26.8028 8.69203 27.4207 8.8923 27.6784 9.39672C27.8808 9.79299 27.7996 10.2587 27.5112 10.5652L27.512 10.566L16.2611 22.5206L15.9401 23.8213L19.9578 27.0566L25.5253 31.5397C25.5301 31.5435 25.5349 31.5475 25.54 31.5512C26.1526 32.0265 26.8218 32.2775 27.4752 32.2778C28.7521 32.2778 29.7687 31.3328 30.0648 29.8705L34.9191 5.90106C35.1138 4.93922 34.9503 4.07806 34.4588 3.47698Z"
                      fill="white"
                    />
                    <path
                      d="M14.3361 21.768C14.3769 21.6021 14.4592 21.4441 14.5844 21.3108L21.967 13.4663L8.26758 20.4651L11.3782 29.3612C11.5555 29.868 11.8193 30.2995 12.1456 30.6373L14.3355 21.7677L14.3361 21.768Z"
                      fill="white"
                    />
                    <path
                      d="M14.0547 31.4612C14.7941 31.455 15.5207 31.1432 16.1573 30.557L18.4332 28.4614L15.3986 26.0181L14.0547 31.4612Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="35" height="35" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="https://www.facebook.com/AnkerPlatform/" target="_blanc">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.2237 18.641H13.0603V34.4355C13.0603 34.7473 13.3129 35 13.6248 35H20.1298C20.4416 35 20.6943 34.7473 20.6943 34.4355V18.7154H25.1048C25.3916 18.7154 25.6328 18.5002 25.6656 18.2154L26.3354 12.4006C26.3538 12.2406 26.3031 12.0804 26.1961 11.9604C26.089 11.8403 25.9356 11.7715 25.7747 11.7715H20.6946V8.12655C20.6946 7.02777 21.2862 6.4706 22.4531 6.4706C22.6194 6.4706 25.7747 6.4706 25.7747 6.4706C26.0866 6.4706 26.3393 6.21781 26.3393 5.90608V0.568694C26.3393 0.256855 26.0866 0.00417742 25.7747 0.00417742H21.1971C21.1648 0.00259677 21.0931 0 20.9874 0C20.1931 0 17.4323 0.155919 15.2515 2.16221C12.8351 4.3855 13.171 7.04753 13.2513 7.50908V11.7714H9.2237C8.91186 11.7714 8.65918 12.0241 8.65918 12.3359V18.0764C8.65918 18.3882 8.91186 18.641 9.2237 18.641Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
