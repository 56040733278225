import React from "react";
import "./CommunityHeader.css";

class CommunityHeader extends React.Component {
  render() {
    return (
      <div className="community-header">
        <div className="container">
          <div className="main-community-header">
            <h2>
              AnkerPay <span>Community</span>
            </h2>
            <h6>A Community-based Coin</h6>
            <button>Telegram</button>
          </div>
        </div>
      </div>
    );
  }
}

export default CommunityHeader;
