import React from "react";
import "./Connect.css";

class Connect extends React.Component {
  render() {
    return (
      <div className="connect">
        <div className="container">
          <h2>
            Connect with <span>us</span>
          </h2>
          <div className="connect-main">
            <div className="connect-block">
              <div className="connect-img">
                <svg
                  width="115"
                  height="115"
                  viewBox="0 0 115 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M115.001 21.8925C110.476 23.8385 105.951 25.0537 101.429 25.5419C106.537 22.4772 109.992 18.1477 111.792 12.5536C107.119 15.3258 102.134 17.2225 96.832 18.2446C92.1616 13.2827 86.4222 10.8008 79.6103 10.8008C73.0924 10.8008 67.5351 13.099 62.9362 17.6963C58.3398 22.2935 56.0414 27.8508 56.0414 34.3691C56.0414 36.1201 56.2356 37.9207 56.6251 39.769C46.9928 39.2827 37.957 36.8626 29.5162 32.5088C21.0759 28.1544 13.9128 22.3533 8.02674 15.1053C5.88625 18.7529 4.81563 22.7182 4.81563 26.999C4.81563 31.0366 5.76409 34.7829 7.66228 38.2368C9.5592 41.6902 12.1137 44.4871 15.3246 46.6278C11.5295 46.4816 7.97844 45.4841 4.67097 43.6355V43.9273C4.67097 49.6194 6.45875 54.6167 10.0338 58.9233C13.6094 63.2293 18.1217 65.9397 23.5696 67.0592C21.5263 67.5936 19.4583 67.8612 17.3669 67.8612C16.0047 67.8612 14.5208 67.7398 12.9157 67.4983C14.4237 72.216 17.1967 76.0942 21.2339 79.1361C25.2718 82.1765 29.8446 83.7437 34.9527 83.8434C26.3912 90.5555 16.6378 93.9115 5.69227 93.9115C3.60008 93.9115 1.70342 93.8161 0.000488281 93.6204C10.9462 100.673 23.0104 104.2 36.1938 104.2C44.561 104.2 52.4175 102.876 59.7609 100.224C67.1078 97.5737 73.3825 94.0216 78.5882 89.5697C83.7923 85.1187 88.2811 79.9987 92.0506 74.2101C95.8205 68.4209 98.6288 62.3772 100.479 56.0776C102.327 49.7768 103.251 43.4663 103.251 37.1412C103.251 35.7789 103.227 34.7579 103.178 34.0765C107.801 30.7215 111.741 26.6588 115.001 21.8925Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="115" height="115" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a href="https://twitter.com/AnkerPay" target="_blanc">
                Twitter
              </a>
            </div>
            <div className="connect-block">
              <div className="connect-img">
                <svg
                  width="115"
                  height="115"
                  viewBox="0 0 115 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M113.224 11.4244C111.818 9.70646 109.714 8.75977 107.299 8.75977C105.985 8.75977 104.594 9.03702 103.164 9.58275L5.81797 46.7423C0.651972 48.7138 -0.0437875 51.6723 0.000958682 53.2612C0.0457049 54.8493 0.908166 57.7639 6.17769 59.4397C6.20928 59.4502 6.24086 59.459 6.27245 59.4687L19.9244 63.3747L86.4121 29.4071C88.0686 28.5595 90.0988 29.2176 90.9455 30.8749C91.6105 32.177 91.3438 33.7071 90.3962 34.7143L90.3989 34.717L53.4315 73.9963L52.3769 78.27L65.5779 88.9003L83.8712 103.631C83.887 103.643 83.9028 103.656 83.9194 103.668C85.9322 105.23 88.1309 106.055 90.2778 106.056C94.4734 106.056 97.8136 102.951 98.7866 98.1461L114.736 19.3892C115.376 16.2289 114.839 13.3993 113.224 11.4244Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M47.1034 71.5242C47.2376 70.9793 47.5079 70.4599 47.9194 70.0221L72.1763 44.2473L27.1641 67.2435L37.3847 96.4734C37.9672 98.1386 38.8341 99.5565 39.9063 100.666L47.1017 71.5233L47.1034 71.5242Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M46.1792 103.372C48.6087 103.352 50.996 102.327 53.0877 100.401L60.5656 93.5156L50.5951 85.4875L46.1792 103.372Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="115" height="115" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a href="https://t.me/AnkerPay" target="_blanc">
                Telegram
              </a>
            </div>
            <div className="connect-block">
              <div className="connect-img">
                <svg
                  width="115"
                  height="115"
                  viewBox="0 0 115 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M30.3089 61.249H42.9148V113.145C42.9148 114.17 43.745 115 44.7696 115H66.1433C67.1679 115 67.9982 114.17 67.9982 113.145V61.4935H82.4896C83.4319 61.4935 84.2247 60.7864 84.3322 59.8505L86.5332 40.7449C86.5937 40.2192 86.4271 39.6928 86.0754 39.2985C85.7234 38.9038 85.2196 38.6778 84.691 38.6778H67.9989V26.7015C67.9989 23.0913 69.9428 21.2605 73.7771 21.2605C74.3235 21.2605 84.691 21.2605 84.691 21.2605C85.7156 21.2605 86.5458 20.4299 86.5458 19.4057V1.86856C86.5458 0.843952 85.7156 0.0137258 84.691 0.0137258H69.6501C69.544 0.00853226 69.3084 0 68.9612 0C66.3514 0 57.2801 0.512306 50.1145 7.1044C42.1751 14.4095 43.2787 23.1562 43.5425 24.6727V38.6775H30.3089C29.2843 38.6775 28.4541 39.5077 28.4541 40.5323V59.3938C28.4541 60.4184 29.2843 61.249 30.3089 61.249Z"
                    fill="#01AAEC"
                  />
                </svg>
              </div>
              <a href="https://www.facebook.com/AnkerPlatform" target="_blanc">
                Facebook
              </a>
            </div>
            <div className="connect-block">
              <div className="connect-img">
                <svg
                  width="115"
                  height="115"
                  viewBox="0 0 115 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M102.183 44.601C98.551 44.601 95.355 46.1295 93.0023 48.511C84.3533 42.4352 72.6904 38.5252 59.7672 38.1083L66.4804 7.41287L87.856 12.2812C87.856 17.5904 92.1206 21.9268 97.3531 21.9268C102.686 21.9268 106.975 17.4754 106.975 12.1614C106.975 6.84746 102.715 2.396 97.3579 2.396C93.6252 2.396 90.4052 4.68162 88.8 7.80579L65.1914 2.49662C64.0031 2.17558 62.8387 3.03808 62.5225 4.24558L55.1529 38.0843C42.3304 38.6258 30.792 42.531 22.1143 48.6116C19.7616 46.1295 16.441 44.601 12.8089 44.601C-0.665245 44.601 -5.07837 62.9483 7.26017 69.2206C6.82413 71.1612 6.62767 73.2312 6.62767 75.2964C6.62767 95.9054 29.5079 112.604 57.6014 112.604C85.8148 112.604 108.695 95.9054 108.695 75.2964C108.695 73.2312 108.479 71.0654 107.947 69.12C120.037 62.8237 115.585 44.6106 102.183 44.601ZM26.8389 70.5239C26.8389 65.1141 31.1035 60.7585 36.4606 60.7585C41.6931 60.7585 45.9625 65.0854 45.9625 70.5239C45.9625 75.8379 41.6979 80.1695 36.4606 80.1695C31.1275 80.1935 26.8389 75.8379 26.8389 70.5239ZM78.7758 93.5143C69.9543 102.465 45.0616 102.465 36.2354 93.5143C35.2627 92.6518 35.2627 91.1281 36.2354 90.1458C37.0835 89.2833 38.5881 89.2833 39.4362 90.1458C46.1733 97.156 68.5216 97.2758 75.551 90.1458C76.3991 89.2833 77.9037 89.2833 78.7518 90.1458C79.7437 91.1329 79.7437 92.6566 78.7758 93.5143ZM78.5793 80.1887C73.3468 80.1887 69.0823 75.8618 69.0823 70.5527C69.0823 65.1429 73.3468 60.7873 78.5793 60.7873C83.9125 60.7873 88.201 65.1141 88.201 70.5527C88.177 75.8379 83.9125 80.1887 78.5793 80.1887Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="115" height="115" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a href="https://www.reddit.com/r/AnkerPay/" target="_blanc">
                Reddit
              </a>
            </div>
            <div className="connect-block">
              <div className="connect-img">
                <svg
                  width="115"
                  height="115"
                  viewBox="0 0 115 115"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M105.824 22.6788L115 13.9005V11.979H83.2121L60.5571 68.3673L34.7827 11.979H1.45187V13.9005L12.1708 26.8044C13.2154 27.758 13.7617 29.1523 13.6227 30.5563V81.2665C13.9533 83.0921 13.3592 84.9705 12.075 86.2978L0 100.932V102.829H34.2365V100.908L22.1615 86.2978C20.8533 84.9657 20.2352 83.1209 20.4987 81.2665V37.4036L50.5521 102.853H54.0452L79.8867 37.4036V89.5417C79.8867 90.9169 79.8867 91.1996 78.9858 92.1005L69.69 101.094V103.021H114.789V101.099L105.829 92.3257C105.043 91.7315 104.636 90.7348 104.803 89.7669V25.2375C104.636 24.2648 105.038 23.2682 105.824 22.6788Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="115" height="115" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a href="https://medium.com/@ankerpay" target="_blanc">
                Medium
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Connect;
