import React from "react";
import "./System.css";

class System extends React.Component {
  render() {
    return (
      <div className="system">
        <div className="container">
          <h2>
            Get a Bitcoin <span>POS system</span>
          </h2>
          <div className="main-system">
            <div className="left">
              <h4>
                <span>Anker</span>
                <br /> POS System
              </h4>
              <p>
                The point of sale systems will be an integral part of adoption.
                These can be deployed alongside the ATM in shopping malls and
                store in order for users to use their newly bought BTC and ANK
                tokens. That way there are no fluctuation risks for the retailer
                or merchant. Perfect for merchants, shops, restaurants and
                hotels. No fluctuation and no risk. Add this one of a kind
                device and see your sales soar. To inquire send us an email to
              </p>
              <a href="mailto:info@ankerpay.com.">info@ankerpay.com.</a>
            </div>
            <div className="right">
              <img src="/img/system.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default System;
