import React from "react";
import "./Mail.css";

class Mail extends React.Component {
  render() {
    return (
      <div className="mail">
        <div className="container">
          <div className="main-mail">
            <div>
              Want to join our email list?
              <br />
              <span>We will keep you in the loop.</span>
            </div>
            <div>
              <input placeholder="Enter Email Address"></input>
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mail;
