import React from "react";
import "./AnkerPlugin.css";

class AnkerPlugin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="ankerplugin">
        <div className="container">
          <div className="main-ankerplugin">
            <h2>
              <span>Anker</span> Plugin
            </h2>
            <p>
              The AnkerPlugin that fits into most websites provides a cryptocurrency e-commerce solution that gives online stores flexibility when accepting payments.
            </p>
          </div>
          <a href="/defi-wallet" target="_blanc">
            LEARN MORE
          </a>
          <div className="ankerplugin-order">
            <img src="/img/order.png" />
          </div>
        </div>
      </div>
    );
  }
}

export default AnkerPlugin;
