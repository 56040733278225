import React from "react";
import CommunityHeader from "./components/Community/CommunityHeader/CommunityHeader";
import CommunityMain from "./components/Community/CommunityMain/CommunityMain";
import Connect from "./components/Community/Connect/Connect";
import Mail from "./components/Ankenode/Mail/Mail";
import Faq from "./components/Community/Faq/Faq";
import Header from "./components/Header/Header";

function App() {
  return (
    <React.Fragment>
      <Header title="Community" />
      <CommunityHeader />
      <CommunityMain />
      <Connect />
      <Faq />
      <Mail />
    </React.Fragment>
  );
}

export default App;
