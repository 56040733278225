import React from "react";
import "./NetworkInfo.css";

class NetworkInfo extends React.Component {
  render() {
    return (
      <div className="networkinfo">
        <div className="container">
          <div className="main-networkinfo">
            <h2>
              <span>Bitcoin ATM's </span>&<span> Private Clients</span>
            </h2>
            <div className="top-networkinfo">
              <div className="left">
                <h4>
                  <span>Bitcoin Kiosk &</span> ATM's
                </h4>
                <p>
                  With our Bitcoin ATM’s in Cape Town and Stellenbosch, it is
                  the easiest and safest way to purchase or sell Bitcoin with
                  cash. Become part of our financial system for the future of
                  South Africa. You will have access to a large network of
                  Bitcoin ATM’s across the country.
                </p>
              </div>
              <div className="right">
                <img src="img/networkinfo-1.png" />
              </div>
            </div>
            <div className="bottom-networkinfo">
              <div className="left">
                <img src="img/networkinfo-2.png" />
              </div>
              <div className="right">
                <h4>
                  <span>Private</span> Clients
                </h4>
                <p>
                  Our team will provide help with your digital transactions
                  whenever you need it. So you can feel safe making large
                  transactions with Anker. At Anker we will assist you
                  personally, or your company buy or sell Bitcoin.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetworkInfo;
