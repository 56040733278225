import React from "react";
import Mail from "./components/Ankenode/Mail/Mail";
import AnkerplatformScheme from "./components/Ankerplatform/AnkerplatformScheme/AnkerplatformScheme";
import MainAnkerplatform from "./components/Ankerplatform/MainAnkerplatform/MainAnkerplatform";
import Solutions from "./components/Ankerplatform/Soultions/Solutions";
import Header from "./components/Header/Header";

function App() {
  return (
    <React.Fragment>
      <Header title="Ankerplatform" />
      <MainAnkerplatform />
      <Solutions />
      <AnkerplatformScheme />
      <Mail />
    </React.Fragment>
  );
}

export default App;
