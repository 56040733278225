import React from "react";
import "./HeaderContact.css";

class HeaderContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="header-contact">
        <div className="container">
          <div className="main-header-contact">
            <div className="contact-block">
              <div className="contact-text">
                <h5>ADDRESS :</h5>
                <a>Cape Town, South Africa</a>
              </div>
              <svg
                width="138"
                height="138"
                viewBox="0 0 138 138"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M114.409 81.1289H83.8937L99.7855 55.7719C103.441 49.9738 105.374 43.2703 105.374 36.3867C105.374 16.3235 89.0506 0 68.9874 0C48.9231 0 32.6006 16.3235 32.6006 36.3867C32.6006 43.2703 34.5326 49.9738 38.1829 55.7624L54.081 81.1289H23.565L0 138H137.974L114.409 81.1289ZM93.6726 113.473H119.058L125.871 129.914H95.7689L93.6726 113.473ZM87.6166 129.914H50.3171L52.3607 113.473H85.5214L87.6166 129.914ZM115.708 105.387H92.6419L90.5804 89.2148H109.006L115.708 105.387ZM40.6866 36.3867C40.6866 20.7813 53.3819 8.08594 68.9874 8.08594C84.5918 8.08594 97.2881 20.7813 97.2881 36.3867C97.2881 41.7415 95.7868 46.9532 92.9398 51.4678L68.9874 89.6876L45.0286 51.4594C42.188 46.9532 40.6866 41.7415 40.6866 36.3867ZM68.9874 104.914L78.8263 89.2148H82.4292L84.4907 105.387H53.3651L55.376 89.2148H59.1484L68.9874 104.914ZM28.9672 89.2148H47.228L45.217 105.387H22.2658L28.9672 89.2148ZM18.9156 113.473H44.2126L42.168 129.914H12.1026L18.9156 113.473Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M89.2021 36.3867C89.2021 25.2401 80.1339 16.1719 68.9873 16.1719C57.8407 16.1719 48.7725 25.2401 48.7725 36.3867C48.7725 47.5333 57.8407 56.6016 68.9873 56.6016C80.1339 56.6016 89.2021 47.5333 89.2021 36.3867ZM56.8584 36.3867C56.8584 29.699 62.2996 24.2578 68.9873 24.2578C75.675 24.2578 81.1162 29.699 81.1162 36.3867C81.1162 43.0745 75.675 48.5156 68.9873 48.5156C62.2996 48.5156 56.8584 43.0745 56.8584 36.3867Z"
                    fill="#01AAEC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="138" height="138" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="contact-block">
              <div className="contact-text">
                <h5>CONTACT</h5>
                <a>info@ankerpay.com</a>
              </div>
              <svg
                width="138"
                height="138"
                viewBox="0 0 138 138"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100.13 130.863C88.2341 136.217 77.9229 138 63.8447 138C30.9317 138 1.98193 114.404 1.98193 75.5442C1.98193 35.0951 31.3263 0 76.1397 0C111.036 0 136.019 23.9926 136.019 57.3023C136.019 86.25 119.761 104.492 98.3469 104.492C89.0276 104.492 82.2872 99.733 81.2953 89.2235H80.8985C74.7532 99.3363 65.8307 104.492 55.3211 104.492C42.4332 104.492 33.1139 94.9742 33.1139 78.7139C33.1139 54.5251 50.959 32.5163 79.5099 32.5163C88.2341 32.5163 98.1464 34.6984 103.106 37.4735L97.1588 74.1556C95.1772 85.8511 96.5637 91.2072 102.116 91.4034C110.642 91.6018 121.348 80.6955 121.348 57.8953C121.348 32.1195 104.693 12.0922 73.9576 12.0922C43.6234 12.0922 17.052 35.8865 17.052 73.7567C17.052 106.868 38.2673 125.705 67.8122 125.705C77.9251 125.705 88.633 123.525 96.5615 119.359L100.13 130.863ZM82.4834 48.9749C80.8964 48.5782 78.9126 48.1814 76.3359 48.1814C63.2518 48.1814 52.9384 61.0693 52.9384 76.3356C52.9384 83.8695 56.3108 88.6283 62.8529 88.6283C70.1884 88.6283 77.9229 79.309 79.7061 67.8098L82.4834 48.9749Z"
                  fill="#01AAEC"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderContact;
