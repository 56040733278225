import React from "react";
import "./NetworkHeader.css";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

class NetworkHeader extends React.Component {
  render() {
    return (
      <header>
        <Menu right>
          <ul>
            <li>
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/ankerpay">
                Ankerpay
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/defi-wallet">
                DEFI wallet
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/ankerplatform">
                Ankerplatform
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/ankenode">
                Ankernode
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/community">
                Community
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
            <div className="downloads-buttons">
              <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                <img src="img/app-store.png" />
              </a>
              <a href="https://ankerpay.com/anker.exe">
                <img src="img/win.png" />
              </a>
              <a href="https://ankerpay.com/community/">
                <img src="img/googleplay.png" />
              </a>
              <a href="https://ankerpay.com/w.apk">
                <img src="img/android.png" />
              </a>
            </div>
          </ul>
        </Menu>
        <div className="container">
          <div className="top-header">
            <div className="logo">
              <a className="">
                <img src="img/top-logo.png" />
              </a>
            </div>
            <div className="top-menu">
              <ul>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankerpay">
                    Ankerpay
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/defi-wallet">
                    DEFI wallet
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankerplatform">
                    Ankerplatform
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankenode">
                    Ankernode
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/community">
                    Community
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="header-soc-link">
              <a href="https://twitter.com/AnkerPay" target="_blanc">
                <svg
                  width="29"
                  height="24"
                  viewBox="0 0 29 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.0005 3.33047C27.8596 3.80427 26.7185 4.10017 25.5781 4.21902C26.8661 3.47285 27.7374 2.4187 28.1913 1.05664C27.0131 1.73164 25.7559 2.19343 24.4188 2.44228C23.2411 1.23418 21.7938 0.629883 20.076 0.629883C18.4323 0.629883 17.0309 1.18944 15.8712 2.3088C14.7121 3.4281 14.1325 4.7812 14.1325 6.36827C14.1325 6.79459 14.1815 7.23299 14.2797 7.68303C11.8507 7.56461 9.5721 6.97537 7.44355 5.91531C5.31513 4.85512 3.50877 3.44266 2.02447 1.67793C1.48469 2.56605 1.21471 3.53153 1.21471 4.57379C1.21471 5.55687 1.45388 6.46901 1.93256 7.30997C2.41091 8.1508 3.0551 8.83176 3.86479 9.35298C2.90776 9.31739 2.01228 9.07451 1.17823 8.62442V8.69548C1.17823 10.0814 1.62906 11.2981 2.5306 12.3467C3.43226 13.3951 4.57014 14.055 5.94397 14.3276C5.42869 14.4577 4.90722 14.5229 4.37982 14.5229C4.0363 14.5229 3.66209 14.4933 3.25734 14.4345C3.6376 15.5832 4.33689 16.5274 5.35496 17.2681C6.37322 18.0083 7.52637 18.3899 8.81448 18.4142C6.65551 20.0484 4.19594 20.8655 1.43577 20.8655C0.908178 20.8655 0.429889 20.8423 0.000453949 20.7947C2.76069 22.5119 5.80296 23.3705 9.12745 23.3705C11.2374 23.3705 13.2187 23.0481 15.0705 22.4024C16.9232 21.7572 18.5055 20.8924 19.8182 19.8084C21.1306 18.7247 22.2625 17.4781 23.2131 16.0687C24.1638 14.6591 24.8719 13.1876 25.3385 11.6538C25.8045 10.1197 26.0377 8.58322 26.0377 7.04319C26.0377 6.71152 26.0315 6.46291 26.0192 6.29701C27.1849 5.48014 28.1785 4.49096 29.0005 3.33047Z"
                    fill="#01AAEC"
                  />
                </svg>
              </a>
              <a href="https://t.me/AnkerPay" target="_blanc">
                <svg
                  width="30"
                  height="24"
                  viewBox="0 0 30 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.5367 0.781583C29.1701 0.363311 28.6212 0.132812 27.9911 0.132812C27.6482 0.132812 27.2855 0.200317 26.9124 0.33319L1.5178 9.38073C0.170151 9.86074 -0.0113512 10.5811 0.000321741 10.9679C0.0119947 11.3546 0.236985 12.0643 1.61164 12.4723C1.61988 12.4748 1.62812 12.477 1.63636 12.4793L5.19775 13.4304L22.5424 5.15999C22.9745 4.95363 23.5041 5.11384 23.725 5.51737C23.8985 5.83439 23.8289 6.20695 23.5817 6.45219L23.5824 6.45283L13.9387 16.0165L13.6636 17.057L17.1073 19.6453L21.8795 23.2318C21.8836 23.2348 21.8878 23.238 21.8921 23.241C22.4172 23.6212 22.9907 23.822 23.5508 23.8222C24.6453 23.8222 25.5167 23.0662 25.7705 21.8964L29.9313 2.72085C30.0982 1.95138 29.9581 1.26245 29.5367 0.781583Z"
                    fill="#01AAEC"
                  />
                </svg>
              </a>
              <a href="https://www.facebook.com/AnkerPlatform" target="_blanc">
                <svg
                  width="16"
                  height="28"
                  viewBox="0 0 16 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.37968 14.9128H4.44893V27.5484C4.44893 27.7979 4.65108 28 4.90055 28H10.1046C10.354 28 10.5562 27.7979 10.5562 27.5484V14.9723H14.0845C14.314 14.9723 14.507 14.8002 14.5332 14.5723L15.0691 9.92049C15.0838 9.7925 15.0432 9.66434 14.9576 9.56832C14.8719 9.47222 14.7492 9.41721 14.6205 9.41721H10.5564V6.50124C10.5564 5.62222 11.0297 5.17648 11.9632 5.17648C12.0963 5.17648 14.6205 5.17648 14.6205 5.17648C14.87 5.17648 15.0721 4.97425 15.0721 4.72486V0.454955C15.0721 0.205484 14.87 0.00334194 14.6205 0.00334194H10.9584C10.9326 0.00207742 10.8752 0 10.7907 0C10.1552 0 7.94659 0.124735 6.20192 1.72977C4.26883 3.5084 4.53754 5.63803 4.60176 6.00727V9.41712H1.37968C1.13021 9.41712 0.92807 9.61926 0.92807 9.86874V14.4611C0.92807 14.7106 1.13021 14.9128 1.37968 14.9128Z"
                    fill="#01AAEC"
                  />
                </svg>
              </a>
              <a href="https://www.reddit.com/r/AnkerPay/" target="_blanc">
                <svg
                  width="30"
                  height="28"
                  viewBox="0 0 30 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6564 10.8595C25.7089 10.8595 24.8751 11.2317 24.2614 11.8115C22.0051 10.3322 18.9626 9.38016 15.5914 9.27866L17.3426 1.805L22.9189 2.99033C22.9189 4.283 24.0314 5.33883 25.3964 5.33883C26.7876 5.33883 27.9064 4.255 27.9064 2.96116C27.9064 1.66733 26.7951 0.583496 25.3976 0.583496C24.4239 0.583496 23.5839 1.14 23.1651 1.90066L17.0064 0.607996C16.6964 0.529829 16.3926 0.739829 16.3101 1.03383L14.3876 9.27283C11.0426 9.40466 8.03264 10.3555 5.76889 11.836C5.15514 11.2317 4.28889 10.8595 3.34139 10.8595C-0.173614 10.8595 -1.32486 15.3267 1.89389 16.8538C1.78014 17.3263 1.72889 17.8303 1.72889 18.3332C1.72889 23.351 7.69764 27.4168 15.0264 27.4168C22.3864 27.4168 28.3551 23.351 28.3551 18.3332C28.3551 17.8303 28.2989 17.303 28.1601 16.8293C31.3139 15.2963 30.1526 10.8618 26.6564 10.8595ZM7.00139 17.1712C7.00139 15.854 8.11389 14.7935 9.51139 14.7935C10.8764 14.7935 11.9901 15.847 11.9901 17.1712C11.9901 18.465 10.8776 19.5197 9.51139 19.5197C8.12014 19.5255 7.00139 18.465 7.00139 17.1712ZM20.5501 22.7688C18.2489 24.9482 11.7551 24.9482 9.45264 22.7688C9.19889 22.5588 9.19889 22.1878 9.45264 21.9487C9.67389 21.7387 10.0664 21.7387 10.2876 21.9487C12.0451 23.6555 17.8751 23.6847 19.7089 21.9487C19.9301 21.7387 20.3226 21.7387 20.5439 21.9487C20.8026 22.189 20.8026 22.56 20.5501 22.7688ZM20.4989 19.5243C19.1339 19.5243 18.0214 18.4708 18.0214 17.1782C18.0214 15.861 19.1339 14.8005 20.4989 14.8005C21.8901 14.8005 23.0089 15.854 23.0089 17.1782C23.0026 18.465 21.8901 19.5243 20.4989 19.5243Z"
                    fill="#01AAEC"
                  />
                </svg>
              </a>
              <a href="https://medium.com/@ankerpay" target="_blanc">
                <svg
                  width="29"
                  height="24"
                  viewBox="0 0 29 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.686 3.52167L29 1.38434V0.916504H20.9839L15.2709 14.6458L8.77129 0.916504H0.366125V1.38434L3.06917 4.52617C3.33258 4.75834 3.47033 5.09784 3.43529 5.43967V17.7865C3.51867 18.231 3.36883 18.6883 3.045 19.0115L0 22.5745V23.0365H8.63354V22.5687L5.58854 19.0115C5.25867 18.6872 5.10279 18.238 5.16925 17.7865V7.10684L12.7479 23.0423H13.6288L20.1453 7.10684V19.8013C20.1453 20.1362 20.1453 20.205 19.9182 20.4243L17.574 22.6142V23.0832H28.9468V22.6153L26.6873 20.4792C26.4891 20.3345 26.3864 20.0918 26.4287 19.8562V4.14467C26.3864 3.90784 26.4879 3.66517 26.686 3.52167Z"
                    fill="#01AAEC"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default NetworkHeader;
