import React from "react";
import "./Download.css";

class Download extends React.Component {
  render() {
    return (
      <div className="download">
        <div className="container">
          <div className="main-download">
            <div className="left">
              <h2>DOWNLOAD TODAY</h2>
              <h4>
                Everything you need, on the go in your pocket.{" "}
                <span>Ankerpay.</span>
              </h4>
              <div className="downloads-block">
                <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                  <img src="img/app-store.png" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet&referrer=utm_source%3DHxM9tZvfwYSNAqngsMF2iG3ZJVB1iXbkbvoVR2rhYtU3">
                  <img src="img/googleplay.png" />
                </a>
              </div>
            </div>
            <div className="right">
              <img src="img/download.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Download;
