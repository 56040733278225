import React from "react";
import "./Faq.css";
import { Collapse } from "react-collapse";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle1: false,
      toggle2: false,
      toggle3: false,
      toggle4: false,
      toggle5: false,
      toggle6: false,
      toggle7: false,
      toggle8: false,
      toggle9: false,
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.toggle5 = this.toggle5.bind(this);
    this.toggle6 = this.toggle6.bind(this);
    this.toggle7 = this.toggle7.bind(this);
    this.toggle8 = this.toggle8.bind(this);
    this.toggle9 = this.toggle9.bind(this);
  }

  toggle1() {
    this.setState({ toggle1: !this.state.toggle1 });
  }
  toggle2() {
    this.setState({ toggle2: !this.state.toggle2 });
  }
  toggle3() {
    this.setState({ toggle3: !this.state.toggle3 });
  }
  toggle4() {
    this.setState({ toggle4: !this.state.toggle4 });
  }
  toggle5() {
    this.setState({ toggle5: !this.state.toggle5 });
  }
  toggle6() {
    this.setState({ toggle6: !this.state.toggle6 });
  }
  toggle7() {
    this.setState({ toggle7: !this.state.toggle7 });
  }
  toggle8() {
    this.setState({ toggle8: !this.state.toggle8 });
  }
  toggle9() {
    this.setState({ toggle9: !this.state.toggle9 });
  }

  render() {
    return (
      <div className="faq">
        <div className="container">
          <h2>Faq</h2>
          <h6>Most frequent questions and answers</h6>
          <div className="main-faq">
            <div className="collapse">
              <div
                className={
                  this.state.toggle1
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle1}
              >
                <span>WHAT IS AN ANKERNODE?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle1}>
                <div className="collapse-text">
                  <p>
                    An AnkNode stabilizes the AnkerNetwork. AnkNodes support the
                    network by maintaining a copy of the blockchain and process
                    transactions. In addition to validating and broadcasting
                    transactions, Anknodes also facilitate other events on the
                    blockchain, such as our quarterly voting events.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle2
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle2}
              >
                <span>HOW MUCH COLLATERAL DO I NEED TO RUN AN ANKERNODE?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle2}>
                <div className="collapse-text">
                  <p>
                    In order to run an AnkNode you will need 4000 ANK. AnkNodes
                    can also vote every 3 months on projects they deem important
                    for the community to grow.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle3
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle3}
              >
                <span>WHAT IS COLD STAKING?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle3}>
                <div className="collapse-text">
                  <p>
                    In order to run an AnkNode you will need 4000 ANK. AnkNodes
                    can also vote every 3 months on projects they deem important
                    for the community to grow.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle4
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle4}
              >
                <span>WHAT IS A ANKERSWAP?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle4}>
                <div className="collapse-text">
                  <p>
                    Blockchainagnostic swap means that you can transfer assets
                    across any chain.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle5
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle5}
              >
                <span>WHAT IS TRANSACTION SURETY?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle5}>
                <div className="collapse-text">
                  <p>
                    Transaction surety is a way in which users can send in
                    certainty by using an alias or identifier. By binding their
                    email address in their wallet to their address. Giving them
                    the ability to send and receive using their email address.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle6
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle6}
              >
                <span>
                  HOW ARE DECENTRALIZED FINANCIAL APPLICATIONS INTEGRATED INTO
                  THE ANKER CHAIN?
                </span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle6}>
                <div className="collapse-text">
                  <p>
                    The AnkerChain with the tools and services offered around it
                    allows for an efficient way to build financial applications
                    on it. Futures, options, and lending are a few examples of
                    this.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle7
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle7}
              >
                <span>HOW DOES THE POINT OF SALE APPLICATION WORK?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle7}>
                <div className="collapse-text">
                  <p>
                    The POS applications allow merchants, store owners, and
                    stores to accept Bitcoin or ANK as payment but they will
                    receive local currency. Corner stores can also instantly
                    become a Bitcoin broker with the buy and sell function.
                    Brokers can set their fee on the server.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle8
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle8}
              >
                <span>HOW DOES ANKERPAY WALLET WORK?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle8}>
                <div className="collapse-text">
                  <p>
                    The AnkerWallet is a multi-currency wallet that allows users
                    to store their currencies and trade them in their wallet.
                    This will grant new users the ease of use and flexibility
                    they need. It also acts as an investment platform.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle9
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle9}
              >
                <span>WHERE DO THE ATM’S FIT IN?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle9}>
                <div className="collapse-text">
                  <p>
                    The ATM’s play a crucial role in spreading crypto to the
                    unbanked masses of Africa and other developing nations. The
                    unbanked portions of the world use cash to buy into crypto
                    and need a place to cash out their investments. Similar to
                    an exchange. Along with the POS and the wallet, it serves a
                    vital function for these communities and will have a lasting
                    impact.
                  </p>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="bottom-features">
            <div>
              Want to run a AnkerNode? <span>Let's help you get started.</span>
            </div>
            <a
              href="https://github.com/AnkerPay/Anker/blob/master/doc/masternode_conf_gui.md"
              target="_blanc"
            >
              start now
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
