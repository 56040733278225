import React from "react";
import "./Growing.css";
import Slider from "react-slick";

class Growing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="growing">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <div className="container">
          <div className="main-growing">
            <h2>
              AnkerPay's growing <span>Network.</span>
            </h2>
            <Slider {...settings}>
              <div className="gowing-slide">
                <div className="growing-block">
                  <a href="/network">
                    <img src="img/growing-1.png" />
                    <span>Bitcoin ATM WillowBridge</span>
                    <a>Buy And Sell Bitcoin for Cash</a>
                  </a>
                </div>
              </div>
              <div className="gowing-slide">
                <div className="growing-block">
                  <a href="/network">
                    <img src="img/growing-2.png" />
                    <span>Bitcoin ATM Cape Town</span>
                    <a>Buy And Sell Bitcoin for Cash</a>
                  </a>
                </div>
              </div>
              <div className="gowing-slide">
                <div className="growing-block">
                  <a href="/network">
                    <img src="img/growing-3.png" />
                    <span>Bitcoin ATM & POS Tranquility Spa</span>
                    <a>Pay with Bitcoin</a>
                  </a>
                </div>
              </div>
              <div className="gowing-slide">
                <div className="growing-block">
                  <a href="/network">
                    <img src="img/growing-4.png" />
                    <span>Bitcoin ATM Stellenbosch</span>
                    <a>Buy And Sell Bitcoin for Cash</a>
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Growing;
