import React from "react";
import Header from "./components/Header/Header";
import MainAnkenode from "./components/Ankenode/MainAnkenode/MainAnkenode";
import AnkenodeSlider from "./components/Ankenode/AnkenodeSlider/AnkenodeSlider";
import Features from "./components/Ankenode/Features/Features";
import Mail from "./components/Ankenode/Mail/Mail";

function App() {
  return (
    <React.Fragment>
      <Header title="Ankenode" />
      <MainAnkenode />
      <AnkenodeSlider />
      <Features />
      <Mail />
    </React.Fragment>
  );
}

export default App;
