import React from "react";
import "./Ecosystem.css";
import { NavLink } from "react-router-dom";

class Ecosystem extends React.Component {
  render() {
    return (
      <div className="ecosystem">
        <div className="container">
          <div className="main-ecosystem">
            <div className="left-ecosystem">
              <h2>
                AnkerPay &nbsp; <span>Ecosystem</span>
              </h2>
              <img src="img/ecosystem.png" />
            </div>
            <div className="right-ecosystem">
              <div className="ecosystem-block">
                <a href="/defi-wallet">
                  <span>
                    Multicurrency
                    <br /> Wallet
                  </span>
                  <img src="img/eco-1.png" />
                  <img className="hover-img" src="img/hover-1.png" />
                </a>
              </div>
              <div className="ecosystem-block">
                <a href="/ankerpay">
                  <span>Anker ATM</span>
                  <img src="img/eco-2.png" />
                  <img className="hover-img" src="img/hover-2.png" />
                </a>
              </div>
              <div className="ecosystem-block">
                <a href="/ankerpay">
                  <span>Anker POS</span>
                  <img src="img/eco-3.png" />
                  <img className="hover-img" src="img/hover-3.png" />
                </a>
              </div>
              <div className="ecosystem-block">
                <a href="/ankenode">
                  <span>Adoption based</span>
                  <img src="img/eco-5.png" />
                  <img className="hover-img" src="img/hover-5.png" />
                </a>
              </div>
              <div className="ecosystem-block">
                <a href="/community">
                  <span>Integrated remittance</span>
                  <img src="img/eco-6.png" />
                  <img className="hover-img" src="img/hover-6.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ecosystem;
