import React from "react";
import "./Videos.css";

class Videos extends React.Component {
  render() {
    return (
      <div className="videos">
        <div className="container">
          <h2>
            How to <span>Buy</span> & <span>Sell</span>?
          </h2>
          <div className="main-videos">
            <div className="video-block">
              <h4>
                How to <span>BUY</span> Bitcoin?
              </h4>
              <video controls>
                <source src="video/buy.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="video-block">
              <h4>
                How to <span>SELL</span> Bitcoin?
              </h4>
              <video controls>
                <source src="video/buy.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Videos;
