import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="top-footer">
            <div className="left-footer">
              <a>
                <img src="/img/footer-logo.png" />
              </a>
              <span>
                Bringing adoption to the masses by simplifying the complex.
              </span>
              <span>Our design is anything but simple.</span>
            </div>
            <div className="center-footer">
              <h6>About us</h6>
              <ul>
                <li>
                  <a
                    href="/TERMS-OF-SERVICE.pdf"
                    target="_blanc"
                  >
                    Terms and Conditions
                  </a>
                  <li>
                    <a href="https://explorer.ankerid.com/" target="_blanc">
                      AnkerExplorer
                    </a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="/network">
                      Bitcoin ATM in Cape Town and Stellenbosch – AnkerPay
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://secureservercdn.net/160.153.137.99/4hh.2d8.myftpupload.com/wp-content/uploads/2019/09/AnkerATM-Privacy-Policy.pdf"
                      target="_blanc"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a>Press Kit</a>
                  </li>
                  <li>
                    <a
                      href="https://ankerpay.typeform.com/to/h74ktT"
                      target="_blanc"
                    >
                      Apply to List on AnkerPay Wallet, POS, ATM
                    </a>
                  </li>
                </li>
              </ul>
            </div>
            <div className="right-footer">
              <h6>CONTACT</h6>
              <a>Cape Town</a>
              <span>
                <a>info@ankerpay.com</a>
              </span>
            </div>
          </div>
          <div className="bottom-footer">
            <div className="downloads-buttons">
              <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                <img src="/img/app-store.png" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet&referrer=utm_source%3DHxM9tZvfwYSNAqngsMF2iG3ZJVB1iXbkbvoVR2rhYtU3">
                <img src="/img/googleplay.png" />
              </a>
              <a href="https://ankerpay.com/w.apk">
                <img src="/img/android.png" />
              </a>
            </div>
            <div className="menu">
              <ul>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankerpay">
                    Ankerpay
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/defi-wallet">
                    DEFI wallet
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankerplatform">
                    Ankerplatform
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankenode">
                    Ankernode
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/community">
                    Community
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
