import React from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

class Header extends React.Component {
  render() {
    return (
      <header className="home">
        <Menu right>
          <ul>
            <li>
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/ankerpay">
                Ankerpay
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/defi-wallet">
                DEFI wallet
              </NavLink>
            </li>


            <li>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
          </ul>
        </Menu>

        <div className="container">
          <div className="menu">
            <ul>
              <li>
                <NavLink exact to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/ankerpay">
                  Ankerpay
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/defi-wallet">
                  DEFI wallet
                </NavLink>
              </li>


              <li>
                <NavLink exact to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="logo">
            <a>
              <img src="/img/logo.png" />
            </a>
          </div>
          <h1>
            Welcome to <span>AnkerPay</span>
          </h1>
          <h5>
            Eliminating the need for <br />
            gatekeepers and centralised intermediaries
          </h5>
          <div className="downloads-buttons">
            <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
              <img src="/img/app-store.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet&referrer=utm_source%3DHxM9tZvfwYSNAqngsMF2iG3ZJVB1iXbkbvoVR2rhYtU3">
              <img src="/img/googleplay.png" />
            </a>
            <a href="https://ankerpay.com/w.apk">
              <img src="/img/android.png" />
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
