import React from "react";
import "./Features.css";

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="features">
        <div className="container">
          <div className="main-features">
            <div className="left-features">
              <h2>Our set of upcoming features.</h2>
              <p>
                At AnkerPay we are building a future never imagined before. We
                set out to produce quality blockchain based tech that can spur
                adoption through ease of use.
              </p>
            </div>
            <div className="right-features">
              <div className="features-block">
                <div className="number">
                  <span>1</span>
                </div>
                <div className="text">
                  <h6>Tokenization</h6>
                  <p>
                    We will add tokenized features to AnkerChain that will add
                    the benefit of our Proof-of-Stake chain's speed to the mix.{" "}
                  </p>
                </div>
              </div>
              <div className="features-block">
                <div className="number">
                  <span>2</span>
                </div>
                <div className="text">
                  <h6>AnkerPay Wallet</h6>
                  <p>
                    As part of our effort to make digital assets accessible and
                    easy to use, users will be able to swap leading crypto
                    currencies in their AnkerWallet.
                  </p>
                </div>
              </div>
              <div className="features-block">
                <div className="number">
                  <span>3</span>
                </div>
                <div className="text">
                  <h6>AnkPlugin</h6>
                  <p>
                    The easy to install AnkPlugin will integrate with any
                    website and allow merchants to accept BTC and ANK payments
                    and receive Fiat globally.
                  </p>
                </div>
              </div>
              <div className="features-block">
                <div className="number">
                  <span>4</span>
                </div>
                <div className="text">
                  <h6>AnkerPlatform</h6>
                  <p>
                    The AnkerPlatform will allow users to create their own
                    decentralized exchange by following a few basic steps. This
                    will then be linked to all other orderbooks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-features">
            <div>
              Want to run a AnkerNode? <span>Let's help you get started.</span>
            </div>
            <a
              href="https://github.com/AnkerPay/Anker/blob/master/doc/masternode_conf_gui.md"
              target="_blanc"
            >
              start now
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Features;
