import React from "react";
import "./Platform.css";
import Slider from "react-slick";

class Platform extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 0,
      slidesToScroll: 1,
      arrows: false,

      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 10000,
          settings: "unslick",
        },
      ],
    };
    return (
      <div className="platform">
        <div className="container">
          <div className="main-platform">
            <div className="left-platform">
              <div className="platfrom-title">
                <h2>
                  AnkerID.
                  <span> AnkerPlatform. Interoperable. AnkerSwaps. </span>
                  AnkerPay
                </h2>
              </div>
              <Slider {...settings}>
                <div>
                  <div className="block-platform">
                    <svg
                      width="88"
                      height="88"
                      viewBox="0 0 88 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M55.9143 78.6494H32.0859C31.1368 78.6494 30.3672 77.8798 30.3672 76.9307C30.3672 75.9816 31.1368 75.2119 32.0859 75.2119H55.9145C56.8636 75.2119 57.6333 75.9816 57.6333 76.9307C57.6333 77.8798 56.8636 78.6494 55.9143 78.6494Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M62.8259 72.6006H25.1743C24.2252 72.6006 23.4556 71.8309 23.4556 70.8818C23.4556 69.9327 24.2252 69.1631 25.1743 69.1631H62.8259C63.775 69.1631 64.5447 69.9327 64.5447 70.8818C64.5447 71.8309 63.7752 72.6006 62.8259 72.6006Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M50.8771 52.7275C52.8569 50.8829 54.097 48.2539 54.097 45.3408C54.097 39.7731 49.5674 35.2437 43.9999 35.2437C38.4323 35.2437 33.9027 39.7732 33.9027 45.3408C33.9027 48.2539 35.1426 50.8827 37.1226 52.7275C33.7042 54.1559 31.2959 57.5345 31.2959 61.4647V63.8486C31.2959 64.7977 32.0656 65.5674 33.0146 65.5674H54.9851C55.9342 65.5674 56.7038 64.7977 56.7038 63.8486V61.4647C56.7038 57.5345 54.2955 54.1559 50.8771 52.7275ZM43.9999 38.6813C47.672 38.6813 50.6595 41.6689 50.6595 45.341C50.6595 49.0131 47.672 52.0006 43.9999 52.0006C40.3278 52.0006 37.3402 49.0131 37.3402 45.341C37.3402 41.6689 40.3278 38.6813 43.9999 38.6813ZM53.2663 62.1299H34.7334V61.4647C34.7334 58.1415 37.4368 55.4381 40.76 55.4381H47.2395C50.5627 55.4381 53.2662 58.1415 53.2662 61.4647V62.1299H53.2663Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M72.1394 48.0975C71.4217 48.0975 70.7638 47.6343 70.523 46.9578C70.2863 46.2925 70.4865 45.5264 71.0276 45.0687C71.5919 44.5916 72.4186 44.532 73.0463 44.9202C73.6482 45.2925 73.9644 46.0213 73.8255 46.7156C73.6667 47.5094 72.9483 48.0975 72.1394 48.0975Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M72.14 40.3593C73.0891 40.3593 73.8587 39.5897 73.8587 38.6406V14.5143C73.8587 10.8011 70.8355 7.77992 67.1194 7.77992H53.3121V1.71875C53.3121 0.769656 52.5425 0 51.5934 0H36.4065C35.4574 0 34.6877 0.769656 34.6877 1.71875V7.78009H20.8805C17.1644 7.78009 14.1411 10.8011 14.1411 14.5145V81.2658C14.1411 84.979 17.1644 88.0002 20.8805 88.0002H67.1192C70.8353 88.0002 73.8586 84.9791 73.8586 81.2658V54.1803C73.8586 53.2312 73.0889 52.4616 72.1398 52.4616C71.1907 52.4616 70.4211 53.2312 70.4211 54.1803V81.2656C70.4211 83.0835 68.9398 84.5625 67.1192 84.5625H20.8805C19.0598 84.5625 17.5786 83.0837 17.5786 81.2656V14.5143C17.5786 12.6964 19.0598 11.2174 20.8805 11.2174H34.6877V15.8029H29.7958C28.8467 15.8029 28.0771 16.5725 28.0771 17.5216V30.343C28.0771 31.2921 28.8467 32.0617 29.7958 32.0617H58.2038C59.1529 32.0617 59.9226 31.2921 59.9226 30.343V17.5216C59.9226 16.5725 59.1529 15.8029 58.2038 15.8029H53.3121V11.2176H67.1194C68.94 11.2176 70.4212 12.6964 70.4212 14.5145V38.6408C70.4212 39.5899 71.1907 40.3593 72.14 40.3593ZM38.1252 3.4375H49.8744V21.2365H38.1252V3.4375ZM56.4853 19.2404V28.6242H31.5146V19.2404H34.6877V22.9551C34.6877 23.9042 35.4574 24.6739 36.4065 24.6739H51.5932C52.5423 24.6739 53.3119 23.9042 53.3119 22.9551V19.2404H56.4853Z"
                          fill="#01AAEC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="88" height="88" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <h6>AnkerID</h6>
                    <p>
                      Use your email to send or receive the Cryptocurrency of
                      your choice. Each email address is linked to an ANK
                      address and gives corporate and individuals control over
                      their transactions without confusing addresses.
                      Transaction surety integration into the wallet and Anker
                      servers will allow a trust-based exchange system to evolve
                      and deliver a true currency.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="block-platform">
                    <svg
                      width="88"
                      height="88"
                      viewBox="0 0 88 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M46.4571 63.3316C34.4429 63.3316 24.6689 53.5574 24.6689 41.5432C24.6689 29.5291 34.4431 19.7549 46.4571 19.7549C58.4711 19.7549 68.2455 29.5291 68.2455 41.5432C68.2455 53.5574 58.4713 63.3316 46.4571 63.3316ZM46.4571 24.9127C37.2871 24.9127 29.8267 32.3732 29.8267 41.5432C29.8267 50.7133 37.2873 58.1738 46.4571 58.1738C55.6273 58.1738 63.0877 50.7133 63.0877 41.5432C63.0877 32.3732 55.6273 24.9127 46.4571 24.9127Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M73.2422 20.7374C69.1108 20.7374 65.7495 17.3762 65.7495 13.2447C65.7495 9.11311 69.1108 5.75195 73.2422 5.75195C77.3736 5.75195 80.7348 9.11311 80.7348 13.2447C80.7348 17.3762 77.3736 20.7374 73.2422 20.7374ZM73.2422 10.9099C71.9548 10.9099 70.9073 11.9573 70.9073 13.2448C70.9073 14.5322 71.9548 15.5798 73.2422 15.5798C74.5296 15.5798 75.577 14.5324 75.577 13.2448C75.577 11.9573 74.5296 10.9099 73.2422 10.9099Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M11.575 31.1683L6.63037 29.7001C9.12674 21.2941 14.3698 13.7335 21.3938 8.41114C28.656 2.90864 37.3226 0 46.4572 0C53.0631 0 59.3814 1.50693 65.2365 4.47902L62.9018 9.07821C57.777 6.4768 52.2442 5.15778 46.4572 5.15778C38.4566 5.15778 30.867 7.70435 24.509 12.5221C18.354 17.1859 13.7607 23.8078 11.575 31.1683Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M58.2996 81.3698L56.8315 76.4254C64.192 74.2397 70.8139 69.6464 75.4778 63.4914C80.2955 57.1332 82.8421 49.5436 82.8421 41.543C82.8421 35.1429 81.1568 28.8492 77.9685 23.342L82.4322 20.7578C86.0746 27.0493 87.9998 34.2366 87.9998 41.543C87.9998 50.6776 85.0914 59.3444 79.5887 66.6064C74.2662 73.6306 66.7056 78.8736 58.2996 81.3698Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M34.6136 81.3699C28.0281 79.4143 21.9662 75.7997 17.0832 70.9167C12.2001 66.0336 8.58537 59.9717 6.62988 53.3862L11.5743 51.918C13.2862 57.683 16.4524 62.9916 20.7302 67.2696C25.0083 71.5476 30.3168 74.7136 36.0819 76.4257L34.6136 81.3699Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M46.4569 87.9998C42.3255 87.9998 38.9644 84.6387 38.9644 80.5071C38.9644 76.3756 42.3255 73.0144 46.4569 73.0144C50.5883 73.0144 53.9496 76.3756 53.9496 80.5071C53.9496 84.6387 50.5883 87.9998 46.4569 87.9998ZM46.4569 78.1724C45.1695 78.1724 44.1221 79.2197 44.1221 80.5073C44.1221 81.7947 45.1695 82.8422 46.4569 82.8422C47.7443 82.8422 48.7918 81.7948 48.7918 80.5073C48.7918 79.2197 47.7443 78.1724 46.4569 78.1724Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M7.49271 49.0358C3.36133 49.0358 0 45.6746 0 41.5432C0 37.4119 3.36133 34.0505 7.49271 34.0505C11.6241 34.0505 14.9854 37.4117 14.9854 41.5432C14.9854 45.6748 11.6241 49.0358 7.49271 49.0358ZM7.49271 39.2083C6.20533 39.2083 5.15778 40.2557 5.15778 41.5432C5.15778 42.8308 6.20533 43.8782 7.49271 43.8782C8.78009 43.8782 9.82764 42.8308 9.82764 41.5432C9.82764 40.2557 8.78009 39.2083 7.49271 39.2083Z"
                        fill="#01AAEC"
                      />
                    </svg>
                    <h6>Decentralized Financial Applications</h6>
                    <p>
                      Our Ankerswaps will enable cross-chain inter-operable
                      swaps. These blockchain agnostic swaps will enable us to
                      create new decentralized Finance applications including
                      decentralized lending, decentralized options and futures
                      trading.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="block-platform">
                    <svg
                      width="88"
                      height="88"
                      viewBox="0 0 88 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          d="M22 5.5V0H2.75C1.23114 0 0 1.23114 0 2.75V22H5.5V9.3775L31.0475 34.925L34.925 31.0475L9.3775 5.5H22Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M85.2502 0H66.0002V5.5H78.6227L53.0752 31.0475L56.9527 34.925L82.5002 9.3775V22H88.0002V2.75C88.0002 1.23114 86.7691 0 85.2502 0Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M82.5002 78.6225L56.9527 53.075L53.0752 56.9525L78.6227 82.5H66.0002V88H85.2502C86.7691 88 88.0002 86.7688 88.0002 85.25V66H82.5002V78.6225Z"
                          fill="#01AAEC"
                        />
                        <path
                          d="M31.0475 53.0476L5.5 78.6226V66.0001H0V85.2501C0 86.769 1.23114 88.0001 2.75 88.0001H22V82.5001H9.3775L34.925 56.9526L31.0475 53.0476Z"
                          fill="#01AAEC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="88" height="88" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <h6>AnkerPlatform</h6>
                    <p>
                      AnkerSwaps can be made cross all chains unifying the
                      crypto market. Send Bitcoin to a Dash user or vice versa.
                      Giving users across the globe flexibility and freedom. The
                      AnkerPlatform will run multiple chains parallel to each
                      other giving AnkerPay cross chain control over
                      transactions and creating a truly inter-operable
                      environment.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="block-platform">
                    <svg
                      width="88"
                      height="88"
                      viewBox="0 0 111 111"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M65.2212 54.4684C75.7117 54.4684 84.2168 44.1385 84.2168 31.3964C84.2168 18.654 81.424 8.32422 65.2212 8.32422C49.0178 8.32422 46.2256 18.654 46.2256 31.3964C46.2259 44.1385 54.7307 54.4684 65.2212 54.4684Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M101.058 88.7651C100.707 66.5675 97.8076 60.2425 75.6234 56.2385C75.6234 56.2385 72.5001 60.2176 65.2215 60.2176C57.943 60.2176 54.8202 56.2385 54.8202 56.2385C32.8779 60.1984 29.8021 66.43 29.3978 88.0437C29.3649 89.8087 29.3498 89.9013 29.3438 89.6963C29.3449 90.0803 29.3466 90.7902 29.3466 92.0285C29.3466 92.0285 34.6283 102.676 65.2218 102.676C95.8153 102.676 101.098 92.0285 101.098 92.0285C101.098 91.2331 101.098 90.6796 101.099 90.3036C101.093 90.4303 101.08 90.1847 101.058 88.7651Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M38.8593 50.3488C41.3962 50.3488 43.7873 49.6005 45.8988 48.2828C42.8281 43.4202 41.1606 37.5475 41.1606 31.3963C41.1606 25.9634 41.5772 19.1574 44.6162 13.5543C42.9779 13.1097 41.0734 12.8716 38.8593 12.8716C25.6994 12.8716 23.4321 21.2612 23.4321 31.6105C23.4321 41.9592 30.3393 50.3488 38.8593 50.3488Z"
                        fill="#01AAEC"
                      />
                      <path
                        d="M39.5742 55.0001C39.3414 55.0107 39.1044 55.0181 38.8594 55.0181C32.9478 55.0181 30.4114 51.7861 30.4114 51.7861C12.3942 55.0381 10.0394 60.1749 9.75407 78.2036C9.73549 79.3649 9.72605 79.5574 9.72119 79.4496C9.72176 79.7552 9.72234 80.2024 9.72234 80.8543C9.72234 80.8543 12.2069 85.8564 24.328 88.2518C24.5353 76.4801 25.5349 68.467 29.8856 62.5017C32.4017 59.0518 35.8181 56.6936 39.5742 55.0001Z"
                        fill="#01AAEC"
                      />
                    </svg>
                    <h6>Smartcontracts</h6>
                    <p>
                      Smart contracts have been widely adopted since its
                      inception with a broad scope of use cases from digital
                      identity to land title recording. AnkerPlatform along with
                      all our products aim to be accessible, valuable and free.
                    </p>
                  </div>
                </div>
              </Slider>
              <button>Learn more</button>
            </div>
            <div className="right-platform">
              <div className="block-platform">
                <svg
                  width="88"
                  height="88"
                  viewBox="0 0 88 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M55.9143 78.6494H32.0859C31.1368 78.6494 30.3672 77.8798 30.3672 76.9307C30.3672 75.9816 31.1368 75.2119 32.0859 75.2119H55.9145C56.8636 75.2119 57.6333 75.9816 57.6333 76.9307C57.6333 77.8798 56.8636 78.6494 55.9143 78.6494Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M62.8259 72.6006H25.1743C24.2252 72.6006 23.4556 71.8309 23.4556 70.8818C23.4556 69.9327 24.2252 69.1631 25.1743 69.1631H62.8259C63.775 69.1631 64.5447 69.9327 64.5447 70.8818C64.5447 71.8309 63.7752 72.6006 62.8259 72.6006Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M50.8771 52.7275C52.8569 50.8829 54.097 48.2539 54.097 45.3408C54.097 39.7731 49.5674 35.2437 43.9999 35.2437C38.4323 35.2437 33.9027 39.7732 33.9027 45.3408C33.9027 48.2539 35.1426 50.8827 37.1226 52.7275C33.7042 54.1559 31.2959 57.5345 31.2959 61.4647V63.8486C31.2959 64.7977 32.0656 65.5674 33.0146 65.5674H54.9851C55.9342 65.5674 56.7038 64.7977 56.7038 63.8486V61.4647C56.7038 57.5345 54.2955 54.1559 50.8771 52.7275ZM43.9999 38.6813C47.672 38.6813 50.6595 41.6689 50.6595 45.341C50.6595 49.0131 47.672 52.0006 43.9999 52.0006C40.3278 52.0006 37.3402 49.0131 37.3402 45.341C37.3402 41.6689 40.3278 38.6813 43.9999 38.6813ZM53.2663 62.1299H34.7334V61.4647C34.7334 58.1415 37.4368 55.4381 40.76 55.4381H47.2395C50.5627 55.4381 53.2662 58.1415 53.2662 61.4647V62.1299H53.2663Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M72.1394 48.0975C71.4217 48.0975 70.7638 47.6343 70.523 46.9578C70.2863 46.2925 70.4865 45.5264 71.0276 45.0687C71.5919 44.5916 72.4186 44.532 73.0463 44.9202C73.6482 45.2925 73.9644 46.0213 73.8255 46.7156C73.6667 47.5094 72.9483 48.0975 72.1394 48.0975Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M72.14 40.3593C73.0891 40.3593 73.8587 39.5897 73.8587 38.6406V14.5143C73.8587 10.8011 70.8355 7.77992 67.1194 7.77992H53.3121V1.71875C53.3121 0.769656 52.5425 0 51.5934 0H36.4065C35.4574 0 34.6877 0.769656 34.6877 1.71875V7.78009H20.8805C17.1644 7.78009 14.1411 10.8011 14.1411 14.5145V81.2658C14.1411 84.979 17.1644 88.0002 20.8805 88.0002H67.1192C70.8353 88.0002 73.8586 84.9791 73.8586 81.2658V54.1803C73.8586 53.2312 73.0889 52.4616 72.1398 52.4616C71.1907 52.4616 70.4211 53.2312 70.4211 54.1803V81.2656C70.4211 83.0835 68.9398 84.5625 67.1192 84.5625H20.8805C19.0598 84.5625 17.5786 83.0837 17.5786 81.2656V14.5143C17.5786 12.6964 19.0598 11.2174 20.8805 11.2174H34.6877V15.8029H29.7958C28.8467 15.8029 28.0771 16.5725 28.0771 17.5216V30.343C28.0771 31.2921 28.8467 32.0617 29.7958 32.0617H58.2038C59.1529 32.0617 59.9226 31.2921 59.9226 30.343V17.5216C59.9226 16.5725 59.1529 15.8029 58.2038 15.8029H53.3121V11.2176H67.1194C68.94 11.2176 70.4212 12.6964 70.4212 14.5145V38.6408C70.4212 39.5899 71.1907 40.3593 72.14 40.3593ZM38.1252 3.4375H49.8744V21.2365H38.1252V3.4375ZM56.4853 19.2404V28.6242H31.5146V19.2404H34.6877V22.9551C34.6877 23.9042 35.4574 24.6739 36.4065 24.6739H51.5932C52.5423 24.6739 53.3119 23.9042 53.3119 22.9551V19.2404H56.4853Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="88" height="88" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h6>AnkerID</h6>
                <p>
                  Use your email to send or receive the Cryptocurrency of your
                  choice. Each email address is linked to an ANK address and
                  gives corporate and individuals control over their
                  transactions without confusing addresses. Transaction surety
                  integration into the wallet and Anker servers will allow a
                  trust-based exchange system to evolve and deliver a true
                  currency.
                </p>
              </div>
              <div className="block-platform">
                <svg
                  width="88"
                  height="88"
                  viewBox="0 0 88 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46.4571 63.3316C34.4429 63.3316 24.6689 53.5574 24.6689 41.5432C24.6689 29.5291 34.4431 19.7549 46.4571 19.7549C58.4711 19.7549 68.2455 29.5291 68.2455 41.5432C68.2455 53.5574 58.4713 63.3316 46.4571 63.3316ZM46.4571 24.9127C37.2871 24.9127 29.8267 32.3732 29.8267 41.5432C29.8267 50.7133 37.2873 58.1738 46.4571 58.1738C55.6273 58.1738 63.0877 50.7133 63.0877 41.5432C63.0877 32.3732 55.6273 24.9127 46.4571 24.9127Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M73.2422 20.7374C69.1108 20.7374 65.7495 17.3762 65.7495 13.2447C65.7495 9.11311 69.1108 5.75195 73.2422 5.75195C77.3736 5.75195 80.7348 9.11311 80.7348 13.2447C80.7348 17.3762 77.3736 20.7374 73.2422 20.7374ZM73.2422 10.9099C71.9548 10.9099 70.9073 11.9573 70.9073 13.2448C70.9073 14.5322 71.9548 15.5798 73.2422 15.5798C74.5296 15.5798 75.577 14.5324 75.577 13.2448C75.577 11.9573 74.5296 10.9099 73.2422 10.9099Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M11.575 31.1683L6.63037 29.7001C9.12674 21.2941 14.3698 13.7335 21.3938 8.41114C28.656 2.90864 37.3226 0 46.4572 0C53.0631 0 59.3814 1.50693 65.2365 4.47902L62.9018 9.07821C57.777 6.4768 52.2442 5.15778 46.4572 5.15778C38.4566 5.15778 30.867 7.70435 24.509 12.5221C18.354 17.1859 13.7607 23.8078 11.575 31.1683Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M58.2996 81.3698L56.8315 76.4254C64.192 74.2397 70.8139 69.6464 75.4778 63.4914C80.2955 57.1332 82.8421 49.5436 82.8421 41.543C82.8421 35.1429 81.1568 28.8492 77.9685 23.342L82.4322 20.7578C86.0746 27.0493 87.9998 34.2366 87.9998 41.543C87.9998 50.6776 85.0914 59.3444 79.5887 66.6064C74.2662 73.6306 66.7056 78.8736 58.2996 81.3698Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M34.6136 81.3699C28.0281 79.4143 21.9662 75.7997 17.0832 70.9167C12.2001 66.0336 8.58537 59.9717 6.62988 53.3862L11.5743 51.918C13.2862 57.683 16.4524 62.9916 20.7302 67.2696C25.0083 71.5476 30.3168 74.7136 36.0819 76.4257L34.6136 81.3699Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M46.4569 87.9998C42.3255 87.9998 38.9644 84.6387 38.9644 80.5071C38.9644 76.3756 42.3255 73.0144 46.4569 73.0144C50.5883 73.0144 53.9496 76.3756 53.9496 80.5071C53.9496 84.6387 50.5883 87.9998 46.4569 87.9998ZM46.4569 78.1724C45.1695 78.1724 44.1221 79.2197 44.1221 80.5073C44.1221 81.7947 45.1695 82.8422 46.4569 82.8422C47.7443 82.8422 48.7918 81.7948 48.7918 80.5073C48.7918 79.2197 47.7443 78.1724 46.4569 78.1724Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M7.49271 49.0358C3.36133 49.0358 0 45.6746 0 41.5432C0 37.4119 3.36133 34.0505 7.49271 34.0505C11.6241 34.0505 14.9854 37.4117 14.9854 41.5432C14.9854 45.6748 11.6241 49.0358 7.49271 49.0358ZM7.49271 39.2083C6.20533 39.2083 5.15778 40.2557 5.15778 41.5432C5.15778 42.8308 6.20533 43.8782 7.49271 43.8782C8.78009 43.8782 9.82764 42.8308 9.82764 41.5432C9.82764 40.2557 8.78009 39.2083 7.49271 39.2083Z"
                    fill="#01AAEC"
                  />
                </svg>
                <h6>Decentralized Financial Applications</h6>
                <p>
                  Our Ankerswaps will enable cross-chain inter-operable swaps.
                  These blockchain agnostic swaps will enable us to create new
                  decentralized Finance applications including decentralized
                  lending, decentralized options and futures trading.
                </p>
              </div>
              <div className="block-platform">
                <svg
                  width="88"
                  height="88"
                  viewBox="0 0 88 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M22 5.5V0H2.75C1.23114 0 0 1.23114 0 2.75V22H5.5V9.3775L31.0475 34.925L34.925 31.0475L9.3775 5.5H22Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M85.2502 0H66.0002V5.5H78.6227L53.0752 31.0475L56.9527 34.925L82.5002 9.3775V22H88.0002V2.75C88.0002 1.23114 86.7691 0 85.2502 0Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M82.5002 78.6225L56.9527 53.075L53.0752 56.9525L78.6227 82.5H66.0002V88H85.2502C86.7691 88 88.0002 86.7688 88.0002 85.25V66H82.5002V78.6225Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M31.0475 53.0476L5.5 78.6226V66.0001H0V85.2501C0 86.769 1.23114 88.0001 2.75 88.0001H22V82.5001H9.3775L34.925 56.9526L31.0475 53.0476Z"
                      fill="#01AAEC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="88" height="88" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h6>AnkerPlatform</h6>
                <p>
                  AnkerSwaps can be made cross all chains unifying the crypto
                  market. Send Bitcoin to a Dash user or vice versa. Giving
                  users across the globe flexibility and freedom. The
                  AnkerPlatform will run multiple chains parallel to each other
                  giving AnkerPay cross chain control over transactions and
                  creating a truly inter-operable environment.
                </p>
              </div>
              <div className="block-platform">
                <svg
                  width="88"
                  height="88"
                  viewBox="0 0 111 111"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M65.2212 54.4684C75.7117 54.4684 84.2168 44.1385 84.2168 31.3964C84.2168 18.654 81.424 8.32422 65.2212 8.32422C49.0178 8.32422 46.2256 18.654 46.2256 31.3964C46.2259 44.1385 54.7307 54.4684 65.2212 54.4684Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M101.058 88.7651C100.707 66.5675 97.8076 60.2425 75.6234 56.2385C75.6234 56.2385 72.5001 60.2176 65.2215 60.2176C57.943 60.2176 54.8202 56.2385 54.8202 56.2385C32.8779 60.1984 29.8021 66.43 29.3978 88.0437C29.3649 89.8087 29.3498 89.9013 29.3438 89.6963C29.3449 90.0803 29.3466 90.7902 29.3466 92.0285C29.3466 92.0285 34.6283 102.676 65.2218 102.676C95.8153 102.676 101.098 92.0285 101.098 92.0285C101.098 91.2331 101.098 90.6796 101.099 90.3036C101.093 90.4303 101.08 90.1847 101.058 88.7651Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M38.8593 50.3488C41.3962 50.3488 43.7873 49.6005 45.8988 48.2828C42.8281 43.4202 41.1606 37.5475 41.1606 31.3963C41.1606 25.9634 41.5772 19.1574 44.6162 13.5543C42.9779 13.1097 41.0734 12.8716 38.8593 12.8716C25.6994 12.8716 23.4321 21.2612 23.4321 31.6105C23.4321 41.9592 30.3393 50.3488 38.8593 50.3488Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M39.5742 55.0001C39.3414 55.0107 39.1044 55.0181 38.8594 55.0181C32.9478 55.0181 30.4114 51.7861 30.4114 51.7861C12.3942 55.0381 10.0394 60.1749 9.75407 78.2036C9.73549 79.3649 9.72605 79.5574 9.72119 79.4496C9.72176 79.7552 9.72234 80.2024 9.72234 80.8543C9.72234 80.8543 12.2069 85.8564 24.328 88.2518C24.5353 76.4801 25.5349 68.467 29.8856 62.5017C32.4017 59.0518 35.8181 56.6936 39.5742 55.0001Z"
                    fill="#01AAEC"
                  />
                </svg>
                <h6>Smartcontracts</h6>
                <p>
                  Smart contracts have been widely adopted since its inception
                  with a broad scope of use cases from digital identity to land
                  title recording. AnkerPlatform along with all our products aim
                  to be accessible, valuable and free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Platform;
