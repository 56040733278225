import React from "react";
import Locations from "./components/Network/Locations/Locations";
import NetworkHeader from "./components/Network/NetworkHeader/NetworkHeader";
import NetworkInfo from "./components/Network/NetworkInfo/NetworkInfo";
import NetworkMain from "./components/Network/NetworkMain/NetworkMain";
import Partners from "./components/Home/Partners/Partners";
import System from "./components/Network/System/System";
import Videos from "./components/Network/Videos/Videos";
import Faq from "./components/Network/Faq/Faq";
import Download from "./components/Network/Download/Download";

function App() {
  return (
    <React.Fragment>
      <NetworkHeader />
      <NetworkMain />
      <Locations />
      <NetworkInfo />
      <Partners title={[<span>Our</span>, " Strategic Partners"]} />
      <System />
      <Videos />
      <Faq />
      <Download />
    </React.Fragment>
  );
}

export default App;
