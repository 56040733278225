import React from "react";
import "./NetworkMain.css";

class NetworkMain extends React.Component {
  render() {
    return (
      <div className="networkmain">
        <div className="container">
          <div className="main-networkmain">
            <div className="downloads-buttons">
              <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                <img src="img/app-store.png" />
              </a>
              <a href="https://ankerpay.com/anker.exe">
                <img src="img/win.png" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet&referrer=utm_source%3DHxM9tZvfwYSNAqngsMF2iG3ZJVB1iXbkbvoVR2rhYtU3">
                <img src="img/googleplay.png" />
              </a>
              <a href="https://ankerpay.com/w.apk">
                <img src="img/android.png" />
              </a>
            </div>
            <h1>Africa’s Biggest Bitcoin ATM Network</h1>
            <div className="icons">
              <div>
                <img src="img/dash-1.png" />
                <a>Buy & Sell DASH</a>
              </div>
              <div>
                <img src="img/Bitcoin.png" />
                <a>Buy & Sell Bitcoin</a>
              </div>
              <div>
                <img src="img/eth.png" />
                <a>Buy Ethereum</a>
              </div>

              <div>
                <img src="img/doge.png" />
                <a>Buy & Sell Doge</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NetworkMain;
