import React from "react";
import "./Exchange.css";

class Exchange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="wallet">
        <div className="container">
        <h2 className="mwallet">
          AnkerPay. <span>Exchange.</span> Coming soon...
        </h2>
          <div className="main-wallet">
            <div className="left-wallet">

              <h6>
                <svg
                  width="62"
                  height="62"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44.4517 22.7147C43.4442 21.7059 41.8063 21.7059 40.7988 22.7147L29.7086 33.8037L25.0767 29.1731C24.0692 28.1643 22.4313 28.1643 21.4238 29.1731C20.415 30.1819 20.415 31.8171 21.4238 32.8259L27.8822 39.2842C28.3859 39.7893 29.0472 40.0412 29.7086 40.0412C30.3699 40.0412 31.0312 39.7893 31.535 39.2842L44.4517 26.3676C45.4605 25.3588 45.4605 23.7235 44.4517 22.7147Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M59.4167 28.4167C57.9907 28.4167 56.8333 29.574 56.8333 31C56.8333 45.2445 45.2445 56.8333 31 56.8333C16.7555 56.8333 5.16667 45.2445 5.16667 31C5.16667 16.7555 16.7555 5.16667 31 5.16667C37.935 5.16667 44.445 7.87658 49.3326 12.7978C50.3363 13.8118 51.9728 13.817 52.9855 12.8108C53.9981 11.8058 54.0033 10.1706 52.9984 9.15792C47.1342 3.25242 39.3209 0 31 0C13.9061 0 0 13.9061 0 31C0 48.0939 13.9061 62 31 62C48.0939 62 62 48.0939 62 31C62 29.574 60.8427 28.4167 59.4167 28.4167Z"
                    fill="#01AAEC"
                  />
                </svg>
                &ensp; Deep order books
              </h6>
              <p>Deep order books that</p><p>can manage your </p><p>volume.</p>
              <h6>
                {" "}
                <svg
                  width="62"
                  height="62"
                  viewBox="0 0 62 62"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44.4517 22.7147C43.4442 21.7059 41.8063 21.7059 40.7988 22.7147L29.7086 33.8037L25.0767 29.1731C24.0692 28.1643 22.4313 28.1643 21.4238 29.1731C20.415 30.1819 20.415 31.8171 21.4238 32.8259L27.8822 39.2842C28.3859 39.7893 29.0472 40.0412 29.7086 40.0412C30.3699 40.0412 31.0312 39.7893 31.535 39.2842L44.4517 26.3676C45.4605 25.3588 45.4605 23.7235 44.4517 22.7147Z"
                    fill="#01AAEC"
                  />
                  <path
                    d="M59.4167 28.4167C57.9907 28.4167 56.8333 29.574 56.8333 31C56.8333 45.2445 45.2445 56.8333 31 56.8333C16.7555 56.8333 5.16667 45.2445 5.16667 31C5.16667 16.7555 16.7555 5.16667 31 5.16667C37.935 5.16667 44.445 7.87658 49.3326 12.7978C50.3363 13.8118 51.9728 13.817 52.9855 12.8108C53.9981 11.8058 54.0033 10.1706 52.9984 9.15792C47.1342 3.25242 39.3209 0 31 0C13.9061 0 0 13.9061 0 31C0 48.0939 13.9061 62 31 62C48.0939 62 62 48.0939 62 31C62 29.574 60.8427 28.4167 59.4167 28.4167Z"
                    fill="#01AAEC"
                  />
                </svg>
                &ensp; Multiple payment rails.
              </h6>
              <p>Fund or withdraw using </p><p>EFT, Debit Card, PNP, </p><p>bank ATMs. </p>
            </div>
            <div className="right-wallet">
              <img src="/img/exchange.png" />
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exchange;
