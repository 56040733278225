import "./App.css";
import "./fonts/HelveticaNeueCyr/stylesheet.css";
import "./fonts/Inter/stylesheet.css";
import "./fonts/fontawesome/css/all.min.css";
import Home from "./Home";
import Ankerpay from "./Ankerpay";
import Ankenode from "./Ankenode";
import Community from "./Community";
import MobileWallet from "./MobileWallet";
import Contact from "./Contact";
import Ankerplatform from "./Ankerplatform";
import Footer from "./components/Footer/Footer";
import { Route } from "react-router-dom";
import Network from "./Network";

function App() {
  return (
    <div className="App">
      <Route path="/" exact component={Home} />
      <Route path="/ankerpay" exact component={Ankerpay} />
      <Route path="/ankenode" exact component={Ankenode} />
      <Route path="/ankerplatform" exact component={Ankerplatform} />
      <Route path="/community" exact component={Community} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/defi-wallet" exact component={MobileWallet} />
      <Route path="/mobile-wallet" exact component={MobileWallet} />
      <Route path="/network" exact component={Network} />
      <Footer />
    </div>
  );
}

export default App;
