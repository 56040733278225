import React from "react";
import Header from "./components/Header/Header";
import MainAnkerpay from "./components/Ankerpay/MainAnkerpay/MainAnkerpay";
import Schema from "./components/Ankerpay/Schema/Schema";
import AnkerPlugin from "./components/Ankerpay/AnkerPlugin/AnkerPlugin";
import Wallet from "./components/Ankerpay/Wallet/Wallet";
import Growing from "./components/Ankerpay/Growing/Growing";
import Map from "./components/Ankerpay/Map/Map";

function App() {
  return (
    <React.Fragment>
      <Header title="Anker Pay" />
      <MainAnkerpay />
      <Schema />
      <AnkerPlugin />
      <Wallet />
      <Growing />
      <Map />
    </React.Fragment>
  );
}

export default App;
