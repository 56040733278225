import React from "react";
import "./Partners.css";

class Partners extends React.Component {
  render() {
    return (
      <div className="partners">
        <div className="container">
          <h2>{this.props.title}</h2>
          <div className="main-partners">

            <div className="partners-block">
              <a href="https://xanpool.com/" target="_blanc">
                <img src="img/xanpool.png" />
              </a>
            </div>

            <div className="partners-block">
              <a href="https://changelly.com/partners" target="_blanc">
                <img src="img/changelly.png" />
              </a>
            </div>
            <div className="partners-block">
              <a href="http://coinbae.org/" target="_blanc">
                <img src="img/coinbae.png" />
              </a>
            </div>

            <div className="partners-block">
              <a href="https://www.dash.org/" target="_blanc">
                <img src="img/dash.png" />
              </a>
            </div>

            <div className="partners-block">
              <a href="https://www.paxos.com/pax-ecosystem/" target="_blanc">
                <img src="img/paxos.png" />
              </a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
