import React from "react";
import "./Video.css";
import Slider from "react-slick";

class Video extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      centerMode: true,
      speed: 500,
      centerPadding: 0,
      slidesToScroll: 1,
      slidesToShow: 5,
      className: "center",
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,

            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="video">
        <div className="main-video">
          <Slider {...settings}>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/o8crJeTfD1c"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/YmImuruXb_U"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/Mynqkl77L-w"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/wJXWL5aTz44"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/pURKOHWblNk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <iframe
                width="100%"
                height="auto"
                src="https://www.youtube.com/embed/vJTX4RTXlNY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default Video;
