import React from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

class Header extends React.Component {
  render() {
    return (
      <header className={this.props.wallet ? "wallet-header" : null}>
        <Menu right>
          <ul>
            <li>
              <NavLink exact to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/ankerpay">
                Ankerpay
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/defi-wallet">
                DEFI wallet
              </NavLink>
            </li>
            
            <li>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
            <div className="downloads-buttons">
              <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                <img src="/img/app-store.png" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.ankerpay.wallet&referrer=utm_source%3DHxM9tZvfwYSNAqngsMF2iG3ZJVB1iXbkbvoVR2rhYtU3">
                <img src="/img/googleplay.png" />
              </a>
              <a href="https://ankerpay.com/w.apk">
                <img src="/img/android.png" />
              </a>
            </div>
          </ul>
        </Menu>
        <div className="container">
          <div className="top-header">
            <div className="logo">
              <a className="">
                <img src="/img/top-logo.png" />
              </a>
            </div>
            <div className="top-menu">
              <ul>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/ankerpay">
                    Ankerpay
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/defi-wallet">
                    DEFI wallet
                  </NavLink>
                </li>
               
                <li>
                  <NavLink exact to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
            <div></div>
          </div>
        </div>
        <div className="main-header">
          <div className="container">
            <div className="main-header-title">
              <div className="header-title">
                <h1>{this.props.title}</h1>
              </div>
              <div className="header-links">
                <a href="https://apps.apple.com/us/app/ankerpay-wallet/id1487931971?ls=1">
                  <img src="/img/app-store.png" />
                </a>
                <a href="https://ankerpay.com/community/">
                  <img src="/img/googleplay.png" />
                </a>
                <a href="https://ankerpay.com/w.apk">
                  <img src="/img/android.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
