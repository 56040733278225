import React from "react";
import "./MobileWallet.css";
import { Collapse } from "react-collapse";

class MobileWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showBuy: false, showVolatility: false, showAccept: false };
    this.toggleBuy = this.toggleBuy.bind(this);
    this.toggleVolatility = this.toggleVolatility.bind(this);
    this.toggleAccept = this.toggleAccept.bind(this);
  }

  toggleBuy() {
    this.setState({ showBuy: !this.state.showBuy });
  }
  toggleVolatility() {
    this.setState({ showVolatility: !this.state.showVolatility });
  }
  toggleAccept() {
    this.setState({ showAccept: !this.state.showAccept });
  }

  render() {
    return (
      <div className="main-ankerpay">
        <div className="container">
          <div className="column">
            <div className="img">
              <img src="img/second-column-img.png" />
            </div>
            <div className="text">
              <h3>
                <span>AnkerPay</span> Wallet
              </h3>
              <p>
                The AnkerPay Wallet has a variety of features that make it an ideal all in one wallet solution with an incredibly user-friendly design that makes trading storing and using the wallet simple. Users can switch coins listed on the wallet and make daily trades that suit them the most for any given situation.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileWallet;
