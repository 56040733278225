import React from "react";
import Mail from "./components/Ankenode/Mail/Mail";
import Form from "./components/Contact/Form/Form";
import HeaderContact from "./components/Contact/HeaderContact/HeaderContact";
import Header from "./components/Header/Header";

function App() {
  return (
    <React.Fragment>
      <Header title="Contact" />
      <HeaderContact />
      <Form />
      <Mail />
    </React.Fragment>
  );
}

export default App;
