import React from "react";
import "./Faq.css";
import { Collapse } from "react-collapse";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle1: false,
      toggle2: false,
      toggle3: false,
      toggle4: false,
      toggle5: false,
      toggle6: false,
      toggle7: false,
      toggle8: false,
      toggle9: false,
      toggle10: false,
      toggle11: false,
      toggle12: false,
      toggle13: false,
    };
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.toggle5 = this.toggle5.bind(this);
    this.toggle6 = this.toggle6.bind(this);
    this.toggle7 = this.toggle7.bind(this);
    this.toggle8 = this.toggle8.bind(this);
    this.toggle9 = this.toggle9.bind(this);
    this.toggle10 = this.toggle10.bind(this);
    this.toggle11 = this.toggle11.bind(this);
    this.toggle12 = this.toggle12.bind(this);
    this.toggle13 = this.toggle13.bind(this);
  }

  toggle1() {
    this.setState({ toggle1: !this.state.toggle1 });
  }
  toggle2() {
    this.setState({ toggle2: !this.state.toggle2 });
  }
  toggle3() {
    this.setState({ toggle3: !this.state.toggle3 });
  }
  toggle4() {
    this.setState({ toggle4: !this.state.toggle4 });
  }
  toggle5() {
    this.setState({ toggle5: !this.state.toggle5 });
  }
  toggle6() {
    this.setState({ toggle6: !this.state.toggle6 });
  }
  toggle7() {
    this.setState({ toggle7: !this.state.toggle7 });
  }
  toggle8() {
    this.setState({ toggle8: !this.state.toggle8 });
  }
  toggle9() {
    this.setState({ toggle9: !this.state.toggle9 });
  }
  toggle10() {
    this.setState({ toggle10: !this.state.toggle10 });
  }
  toggle11() {
    this.setState({ toggle11: !this.state.toggle11 });
  }
  toggle12() {
    this.setState({ toggle12: !this.state.toggle12 });
  }
  toggle13() {
    this.setState({ toggle13: !this.state.toggle13 });
  }
  render() {
    return (
      <div className="faq">
        <div className="container">
          <h2 style={{ marginBottom: "50px" }}>Faq</h2>
          <div className="main-faq">
            <div className="collapse">
              <div
                className={
                  this.state.toggle1
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle1}
              >
                <span>What is Bitcoin?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle1}>
                <div className="collapse-text">
                  <p>
                    Bitcoin is a consensus network that enables a new payment
                    system and a completely digital money. It is the first
                    decentralized peer-to-peer payment network that is powered
                    by its users with no central authority or middlemen. From a
                    user perspective, Bitcoin is pretty much like cash for the
                    Internet.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle2
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle2}
              >
                <span>Who created Bitcoin?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle2}>
                <div className="collapse-text">
                  <p>
                    Bitcoin is the first implementation of a concept called
                    “cryptocurrency”, which was first described in 1998 by Wei
                    Dai on the cypherpunks mailing list, suggesting the idea of
                    a new form of money that uses cryptography to control its
                    creation and transactions, rather than a central authority.
                    The first Bitcoin specification and proof of concept was
                    published in 2009 in a cryptography mailing list by Satoshi
                    Nakamoto. Satoshi left the project in late 2010 without
                    revealing much about himself. The community has since grown
                    exponentially with many developers working on Bitcoin.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle3
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle3}
              >
                <span>Can I buy Bitcoin with Cash?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle3}>
                <div className="collapse-text">
                  <p>
                    Yes, you can Buy Bitcoin for cash at any of our ATMs. All
                    our machines are two way ATMs so you can also Sell Bitcoin
                    for cash. We offer the following currencies and sites: Buy
                    Bitcoin Capetown, Sell Bitcoin Capetown. Buy Bitcoin
                    Stellenbosch, Sell Bitcoin Stellenbosch. We also Buy and
                    Sell Altcoins including DASH, NANO and Ethereum
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle4
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle4}
              >
                <span>How does Bitcoin work?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle4}>
                <div className="collapse-text">
                  <p>
                    From a user perspective, Bitcoin is nothing more than a
                    mobile app or computer program that provides a personal
                    Bitcoin wallet and allows a user to send and receive
                    bitcoins with them. This is how Bitcoin works for most
                    users. Behind the scenes, the Bitcoin network is sharing a
                    public ledger called the “block chain”. This ledger contains
                    every transaction ever processed, allowing a user’s computer
                    to verify the validity of each transaction. The authenticity
                    of each transaction is protected by digital signatures
                    corresponding to the sending addresses, allowing all users
                    to have full control over sending bitcoins from their own
                    Bitcoin addresses. In addition, anyone can process
                    transactions using the computing power of specialized
                    hardware and earn a reward in bitcoins for this service.
                    This is often called “mining”. To learn more about Bitcoin,
                    you can consult the{" "}
                    <a href="https://bitcoin.org/en/how-it-works">
                      dedicated page
                    </a>
                    and the
                    <a href="https://bitcoin.org/en/bitcoin-paper">
                      original paper
                    </a>
                    .
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle5
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle5}
              >
                <span>Can I buy bitcoin Anonymously?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle5}>
                <div className="collapse-text">
                  <p>
                    Yes, we do allow anonymous transactions up to R24 000 after
                    which we do regular KYC and AML requiring an ID, proof of
                    address and source of funds
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle6
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle6}
              >
                <span>Can I sell Bitcoin Anonymously In South Africa?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle6}>
                <div className="collapse-text">
                  <p>
                    Yes, we do allow anonymous transactions up to R24 000 after
                    which we do regular KYC and AML requiring an ID, proof of
                    address and source of funds.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle7
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle7}
              >
                <span>Is Bitcoin anonymous?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle7}>
                <div className="collapse-text">
                  <p>
                    Bitcoin is designed to allow its users to send and receive
                    payments with an acceptable level of privacy as well as any
                    other form of money. However, Bitcoin is not anonymous and
                    cannot offer the same level of privacy as cash. The use of
                    Bitcoin leaves extensive public records. Various mechanisms
                    exist to protect users’ privacy, and more are in
                    development. However, there is still work to be done before
                    these features are used correctly by most Bitcoin users.
                    Some concerns have been raised that private transactions
                    could be used for illegal purposes with Bitcoin. However, it
                    is worth noting that Bitcoin will undoubtedly be subjected
                    to similar regulations that are already in place inside
                    existing financial systems. Bitcoin cannot be more anonymous
                    than cash and it is not likely to prevent criminal
                    investigations from being conducted. Additionally, Bitcoin
                    is also designed to prevent a large range of financial
                    crimes.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle8
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle8}
              >
                <span>What happens when bitcoins are lost?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle8}>
                <div className="collapse-text">
                  <p>
                    When a user loses his wallet, it has the effect of removing
                    money out of circulation. Lost bitcoins still remain in the
                    block chain just like any other bitcoins. However, lost
                    bitcoins remain dormant forever because there is no way for
                    anybody to find the private key(s) that would allow them to
                    be spent again. Because of the law of supply and demand,
                    when fewer bitcoins are available, the ones that are left
                    will be in higher demand and increase in value to
                    compensate.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle9
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle9}
              >
                <span>Is there a Bitcoin ATM in South Africa?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle9}>
                <div className="collapse-text">
                  <p>
                    Yes. AnkerPay currently operates two of them one in Cape
                    Town and one in Stellenbosch. There are more to come in Port
                    Elizabeth, Durban, Bloemfontein and Johannesburg contact us
                    for further details. Searching for the following keywords
                    will help you find us: Bitcoin, Bitcoin machine near me,
                    bitcoin atm machine near me, Bitcoin machine locations. Buy
                    Bitcoin Western Cape, Sell bitcoin Capetown.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle10
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle10}
              >
                <span>Do BitcoinATM's dispense cash?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle10}>
                <div className="collapse-text">
                  <p>
                    Not all Bitcoin ATM’s dispense cash, however, all AnkerPay
                    Bitcoin ATM’s dispense cash.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle11
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle11}
              >
                <span>How long does a Bitcoin ATM transaction take?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle11}>
                <div className="collapse-text">
                  <p>
                    It depends. When buying Bitcoin it is almost instant. When
                    selling the seller has to wait for one confirmation before
                    he can withdraw. The Bitcoin network has a 10 minutes block
                    time. So typically less than ten minutes.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle12
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle12}
              >
                <span>Are Bitcoin ATM's safe?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle12}>
                <div className="collapse-text">
                  <p>
                    Yes, Bitcoin ATM’s are safe and a very convenient way to buy
                    and sell Bitcoin.
                  </p>
                </div>
              </Collapse>
            </div>
            <div className="collapse">
              <div
                className={
                  this.state.toggle13
                    ? "collapse-title active"
                    : "collapse-title"
                }
                onClick={this.toggle13}
              >
                <span>Where will you place the next Bitcoin ATM?</span>
                <i className="fas fa-chevron-right"></i>
              </div>
              <Collapse isOpened={this.state.toggle13}>
                <div className="collapse-text">
                  <p>
                    We are expanding key sites across South Africa. Some of
                    these sites are but not limited to: Klerksdrop,
                    Potchefstroom, Mahikeng, Mbombela Upinton, Kimberly,
                    Polokwane, Durban, Umhlanga, Pietermaritzburg, Pretoria,
                    Johannesburg, Nelspruit, Soweto, Bloemfontein, Bethlehem,
                    Kroonstad, Welkom, Port Elizabeth, East London, Mosselbay,
                    Beaufort west, Somerset West, Paarl and Lesotho.
                  </p>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
