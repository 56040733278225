import React from "react";
import "./Timeline.css";

class Timeline extends React.Component {
  render() {
    return (
      <div className="timeline">
        <div className="container">
          <div className="main-timeline">
            <h2>
              The AnkerPay <span>Timeline</span>
            </h2>

            <svg
              className="main-svg"
              width="100%"
              height="1407"
              viewBox="0 0 1115 1407"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="477"
                y="155"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="138"
                y="155"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <path
                d="M638 0V27.5C638 55.1142 615.614 77.5 588 77.5H51C23.3858 77.5 1 99.8858 1 127.5V173.497C1 201.111 23.3858 223.496 51 223.496H1064C1091.61 223.496 1114 245.882 1114 273.496V421.5C1114 449.114 1091.61 471.5 1064 471.5H51C23.3857 471.5 1 493.886 1 521.5V677C1 704.614 23.3858 727 51 727H1064C1091.61 727 1114 749.386 1114 777V979C1114 1006.61 1091.61 1029 1064 1029H51C23.3857 1029 1 1051.39 1 1079V1277C1 1304.61 23.3858 1327 51 1327H287"
                stroke="#01AAEC"
              />
              <rect
                x="821"
                y="155"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="477"
                y="405"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="138"
                y="405"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="821"
                y="405"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="477"
                y="655"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="138"
                y="655"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="138"
                y="960"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="138"
                y="1258"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="477"
                y="960"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="821"
                y="960"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
              <rect
                x="821"
                y="655"
                width="148"
                height="148"
                rx="9"
                stroke="#01AAEC"
                stroke-width="2"
              />
            </svg>
            <svg
              className="mobile-svg"
              width="100%"
              height="1762"
              viewBox="0 0 472 1762"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M268.921 0V27.5C268.921 54.8381 246.76 77 219.421 77H51C23.1096 77 0.5 99.6096 0.5 127.5V173.497C0.5 201.387 23.1096 223.996 51 223.996H58V222.996H51C23.6619 222.996 1.5 200.835 1.5 173.497V127.5C1.5 100.162 23.6619 78 51 78H219.421C247.312 78 269.921 55.3904 269.921 27.5V0H268.921ZM164 222.996V223.996H296V222.996H164ZM420 223.996H402V222.996H420C447.89 222.996 470.5 245.606 470.5 273.496V421.5C470.5 449.39 447.89 472 420 472H402V471H420C447.338 471 469.5 448.838 469.5 421.5V273.496C469.5 246.158 447.338 223.996 420 223.996ZM296 471V472H164V471H296ZM58 472V471H51C23.1096 471 0.5 493.61 0.5 521.5V677C0.5 704.89 23.1096 727.5 51 727.5H58V726.5H51C23.6619 726.5 1.5 704.338 1.5 677V521.5C1.5 494.162 23.6619 472 51 472H58ZM164 726.5V727.5H296V726.5H164ZM420 727.5H402V726.5H420C447.89 726.5 470.5 749.11 470.5 777V938C470.5 965.89 447.89 988.5 420 988.5H402V987.5H420C447.338 987.5 469.5 965.338 469.5 938V777C469.5 749.662 447.338 727.5 420 727.5ZM296 988.5V987.5H236H164V988.5H236H296ZM51 987.5H58V988.5H51C23.6619 988.5 1.5 1010.66 1.5 1038V1199C1.5 1226.34 23.6619 1248.5 51 1248.5H58V1249.5H51C23.1096 1249.5 0.5 1226.89 0.5 1199V1038C0.5 1010.11 23.1096 987.5 51 987.5ZM164 1249.5V1248.5H296V1249.5H164ZM402 1249.5H421C448.338 1249.5 470.5 1271.66 470.5 1299V1459C470.5 1486.34 448.338 1508.5 421 1508.5H402V1509.5H421C448.89 1509.5 471.5 1486.89 471.5 1459V1299C471.5 1271.11 448.89 1248.5 421 1248.5H402V1249.5ZM296 1509.5V1508.5H238.5H164V1509.5H238.5H296ZM58 1508.5V1509.5H51C23.6619 1509.5 1.5 1531.66 1.5 1559V1711.5C1.5 1738.84 23.6619 1761 51 1761H58V1762H51C23.1096 1762 0.5 1739.39 0.5 1711.5V1559C0.5 1531.11 23.1096 1508.5 51 1508.5H58Z"
                fill="#01AAEC"
              />
            </svg>

            <div>
              <div className="timeline-block">
                <p>
                  July <br />
                  2018
                </p>
                <span>AnkerPay Conceived</span>
              </div>
              <div className="timeline-block">
                <p>
                  August <br />
                  2018
                </p>
                <span>Development Started</span>
              </div>
              <div className="timeline-block">
                <p>
                  February <br />
                  2019
                </p>
                <span>AnkerPOS Development</span>
              </div>
              <div className="timeline-block">
                <p>
                  May <br />
                  2019
                </p>
                <span>AnkerPOS Development</span>
              </div>
              <div className="timeline-block">
                <p>
                  July <br />
                  2018
                </p>
                <span>AnkerPOS Testing</span>
              </div>
              <div className="timeline-block">
                <p>
                  October <br />
                  2019
                </p>
                <span>AnkerPay Wallet Launch</span>
              </div>
              <div className="timeline-block">
                <p>
                  December <br />
                  2019
                </p>
                <span>AnkerATM Launch</span>
              </div>
              <div className="timeline-block">
                <p>
                  February <br />
                  2020
                </p>
                <span>AnkerChain Development</span>
              </div>
              <div className="timeline-block">
                <p>
                  August <br />
                  2020
                </p>
                <span>AnkerChain Launched</span>
              </div>
              <div className="timeline-block">
                <p>
                  October <br />
                  2020
                </p>
                <span>Exchange Listing</span>
              </div>
              <div className="timeline-block">
                <p>
                  September- <br />
                  December <br />
                  2020
                </p>
                <span>AnkerPOS and ATM Deployment</span>
              </div>
              <div className="timeline-block">
                <p>
                  December <br />
                  2020
                </p>
                <span>Defi- Infrastructure</span>
              </div>
              <div className="timeline-block">
                <p>
                  January <br />
                  2021
                </p>
                <span>AnkerPay Network, rest of Africa</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
