import React from "react";
import "./Banking.css";
import Slider from "react-slick";

class Banking extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="banking">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <div className="container">
          <div className="main-banking">
            <h2>
              Banking for the <span>Unbanked</span>
            </h2>
            <Slider {...settings}>
              <div>
                <div className="banking-slide">
                  <svg
                    width="82"
                    height="82"
                    viewBox="0 0 82 82"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.1523 72.3906C27.7311 72.3906 27.3179 72.562 27.02 72.8599C26.7221 73.1578 26.5508 73.571 26.5508 73.9922C26.5508 74.4134 26.7221 74.8266 27.02 75.1245C27.3179 75.4224 27.7295 75.5938 28.1523 75.5938C28.5736 75.5938 28.9868 75.4224 29.2846 75.1245C29.5825 74.8266 29.7539 74.4134 29.7539 73.9922C29.7539 73.571 29.5825 73.1578 29.2846 72.8599C28.9868 72.562 28.5734 72.3906 28.1523 72.3906Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M25.0288 9.60938H31.2749C32.1594 9.60938 32.8765 8.89236 32.8765 8.00781C32.8765 7.12327 32.1594 6.40625 31.2749 6.40625H25.0288C24.1443 6.40625 23.4272 7.12327 23.4272 8.00781C23.4272 8.89236 24.1443 9.60938 25.0288 9.60938Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M76.9819 21.4609H55.3784V6.72656C55.3784 3.0175 52.3609 0 48.6519 0H7.65186C3.9428 0 0.925293 3.0175 0.925293 6.72656V75.2734C0.925293 78.9825 3.9428 82 7.65186 82H48.6519C52.3609 82 55.3784 78.9825 55.3784 75.2734V60.5391H76.9819C79.2388 60.5391 81.0748 58.703 81.0748 56.4461V25.5539C81.0747 23.297 79.2388 21.4609 76.9819 21.4609ZM7.65202 3.20312H48.652C50.5949 3.20312 52.1755 4.78371 52.1755 6.72656V12.8125H4.12858V6.72656C4.12858 4.78371 5.70916 3.20312 7.65202 3.20312ZM48.6519 78.7969H7.65186C5.709 78.7969 4.12842 77.2163 4.12842 75.2734V69.1875H52.1753V75.2734C52.1753 77.2163 50.5947 78.7969 48.6519 78.7969ZM52.1753 65.9844H4.12842V16.0156H52.1753V21.4609H29.7534V20.5C29.7534 19.6155 29.0364 18.8984 28.1519 18.8984C27.2673 18.8984 26.5503 19.6155 26.5503 20.5V61.5C26.5503 62.3845 27.2673 63.1016 28.1519 63.1016C29.0364 63.1016 29.7534 62.3845 29.7534 61.5V60.5391H52.1753V65.9844ZM77.8716 56.4461C77.8716 56.9367 77.4725 57.3359 76.9817 57.3359H29.7536V24.6641H76.9819C77.4725 24.6641 77.8717 25.0632 77.8717 25.5539V56.4461H77.8716Z"
                      fill="black"
                    />
                    <path
                      d="M38.6157 38.0104C41.0924 38.0104 43.1001 36.0026 43.1001 33.526C43.1001 31.0493 41.0924 29.0416 38.6157 29.0416C36.1391 29.0416 34.1313 31.0493 34.1313 33.526C34.1313 36.0026 36.1391 38.0104 38.6157 38.0104Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M34.1313 46.4453H39.3966C40.2812 46.4453 40.9982 45.7283 40.9982 44.8438C40.9982 43.9592 40.2812 43.2422 39.3966 43.2422H34.1313C33.2468 43.2422 32.5298 43.9592 32.5298 44.8438C32.5298 45.7283 33.2468 46.4453 34.1313 46.4453Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M50.7626 50.2891H34.1313C33.2468 50.2891 32.5298 51.0061 32.5298 51.8906C32.5298 52.7752 33.2468 53.4922 34.1313 53.4922H50.7626C51.647 53.4922 52.3642 52.7752 52.3642 51.8906C52.3642 51.0061 51.647 50.2891 50.7626 50.2891Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M73.494 50.2891H68.2285C67.3441 50.2891 66.627 51.0061 66.627 51.8906C66.627 52.7752 67.3441 53.4922 68.2285 53.4922H73.494C74.3783 53.4922 75.0955 52.7752 75.0955 51.8906C75.0955 51.0061 74.3785 50.2891 73.494 50.2891Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M50.7625 43.2422H45.4971C44.6127 43.2422 43.8955 43.9592 43.8955 44.8438C43.8955 45.7283 44.6127 46.4453 45.4971 46.4453H50.7625C51.6469 46.4453 52.3641 45.7283 52.3641 44.8438C52.3641 43.9592 51.6469 43.2422 50.7625 43.2422Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M62.1282 43.2422H56.8628C55.9784 43.2422 55.2612 43.9592 55.2612 44.8438C55.2612 45.7283 55.9784 46.4453 56.8628 46.4453H62.1282C63.0126 46.4453 63.7298 45.7283 63.7298 44.8438C63.7298 43.9592 63.0126 43.2422 62.1282 43.2422Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M73.494 43.2422H68.2285C67.3441 43.2422 66.627 43.9592 66.627 44.8438C66.627 45.7283 67.3441 46.4453 68.2285 46.4453H73.494C74.3783 46.4453 75.0955 45.7283 75.0955 44.8438C75.0955 43.9592 74.3785 43.2422 73.494 43.2422Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M66.7676 29.0416H65.0236C63.6477 29.0416 62.5322 30.157 62.5322 31.533V35.5191C62.5322 36.8951 63.6476 38.0105 65.0236 38.0105H66.7676V29.0416Z"
                      fill="#01AAEC"
                    />
                    <path
                      d="M73.4941 35.5191V31.533C73.4941 30.1571 72.3786 29.0416 71.0027 29.0416H69.2588V38.0104H71.0027C72.3786 38.0104 73.4941 36.895 73.4941 35.5191Z"
                      fill="#01AAEC"
                    />
                  </svg>
                  <h6>Simple integration.</h6>
                  <p>
                    AnkerPay’s APIs allow for simple integration into current and existing payment providers. add invoices to your website or application to allow users to pay with bitcoin or other crypto currencies.
                  </p>
                </div>
              </div>
              <div>
                <div className="banking-slide">
                  <img src="/img/banking2.svg" width="82" height="82"   className="banking-imge" />

                  <h6>Africa-wide on and off ramps.</h6>
                  <p>
                    with ankerPay has access to 34 african countries and various that allow users to seamlessly buy or sell crypto currencies, remit, and load funds. 
                  </p>
                </div>
              </div>
              <div>
                <div className="banking-slide">
                  <img src="/img/banking3.svg" width="82" height="82"  className="banking-imge" />
                  <h6>GATEWAY.</h6>
                  <p>
                    AnkerPay is building a comprehensive solution for the unbanked. This includes a mobile exchange, liquidity provider mechanism and a digital asset gateway. 
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Banking;
